import React from 'react';

// React Bootstrap
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = (props) => {
	const { show, onHide, dialogFunction } = props;

	const { t } = useTranslation();

	const imgStyles = {
		height: '30vh',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: '1rem'
	};
	
	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Body>
				<img src={process.env.PUBLIC_URL + '/assets/question.png'} style={imgStyles} alt='Question' />

				<Container className='text-center mb-3'>
					<Modal.Title as='h2'>{t('confirmDialog.title')}</Modal.Title>
					<p className='py-3'>{t('confirmDialog.text')}</p>

					<Row>
						<Col className='d-flex justify-content-evenly'>
							<Button variant='danger' onClick={onHide}>{t('confirmDialog.cancel')}</Button>
							<Button variant='primary' onClick={dialogFunction}>{t('confirmDialog.confirm')}</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>
	);
}

ConfirmDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	dialogFunction: PropTypes.any
}

export default ConfirmDialog;
