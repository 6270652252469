import Axios from 'axios';

import {
	COUNTERS_LOADING_TRUE,
	COUNTERS_LOADING_FALSE,
	COUNTERS_DATA_GET,
	COUNTERS_DATA_GET_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const counters_loading_true = () => dispatch => {
	dispatch({
		type: COUNTERS_LOADING_TRUE
	})
}

export const counters_loading_false = () => dispatch => {
	dispatch({
		type: COUNTERS_LOADING_FALSE
	})
}

const getAreaType = (areaFilters) => {
	let area_type = 1, area = '';  // if there's no area filters

	if (areaFilters.region !== '') {
		area_type = 2;
		area = 'region';
	}
	if (areaFilters.zone !== '') {
		area_type = 3;
		area = 'zone';
	}
	if (areaFilters.depot !== '') {
		area_type = 4;
		area = 'depot';
	}
	if (areaFilters.group !== '') {
		area_type = 5;
		area = 'group';
	}

	return { area_type, area };
}

const createCountersCurrentDataFilters = (orgFilters = {}, areaFilters = {}) => {
	let { area_type, area } = getAreaType(areaFilters);

	return {
		submodule: orgFilters.submodule,
		cycle: orgFilters.cycle,
		area_type: area_type,
		area: area_type === 1 ? orgFilters.organization : areaFilters[area]
	};
}

export const get_counters_current_data = (orgFilters = {}, areaFilters = {}) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/counters/data/current?';
	let filters = createCountersCurrentDataFilters(orgFilters, areaFilters);
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: COUNTERS_DATA_GET,
			payload: {
				items: res.data.filter(counter => counter.counter.counter_type === 1),
				photos: res.data.filter(counter => counter.counter.counter_type === 2)
			}
		});
	}).catch((err) => {
		dispatch({
			type: COUNTERS_DATA_GET_ERROR,
			payload: { type: 'counters_data', msg: Object.values(err.response.data)[0] }
		});
	})
}

const createCountersDataFilters = (orgFilters = {}, areaFilters = {}, current = false) => {
	let { area_type, area } = getAreaType(areaFilters);

	if (area_type === 1) { // if there's no area filters
		return {
			organization: orgFilters.organization,
			submodule: orgFilters.submodule,
			cycle: orgFilters.cycle,
			area_type: area_type
		};
	}
	else {
		return {
			submodule: orgFilters.submodule,
			cycle: orgFilters.cycle,
			area_type: area_type,
			[`${area}`]: areaFilters[area]
		};
	}
}

export const get_counters_data = (orgFilters = {}, areaFilters = {}) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/counters/data/history?';
	let filters = createCountersDataFilters(orgFilters, areaFilters);
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: COUNTERS_DATA_GET,
			payload: {
				items: res.data.filter(counter => counter.counter.counter_type === 1),
				photos: res.data.filter(counter => counter.counter.counter_type === 2)
			}
		});
	}).catch((err) => {
		dispatch({
			type: COUNTERS_DATA_GET_ERROR,
			payload: { type: 'counters_data', msg: Object.values(err.response.data)[0] }
		});
	})
}
