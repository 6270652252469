import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Modal, Container, Button, Form, FloatingLabel, Spinner } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Lottie from 'lottie-react';

// Components
import Alert from '../utils/Alert';

// Actions
import { get_organizations, get_workspaces, get_modules } from '../../actions/organizationActions';
import { create_report, update_report } from '../../actions/reportsActions';
import { get_all_apps } from '../../actions/appsActions';

// Utils
import errorExists from '../../utils/errorExists';
import isEmpty from '../../utils/isEmpty';

// Icons
import checkAnimation from '../../static/icons/checkAnimation.json';

const ReportModal = (props) => {
	const { show, onHide, create, filters, updateReport, skip, limit } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	const { create_report_success, update_report_success, reports_errors } = useSelector(state => state.reports);

	const { all_apps_loading, all_apps, apps_errors } = useSelector(state => state.apps);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });

	const initialReportInfo = { app: '', alias: '', name: '', description: '', action: '', create_tab: '' };
	const [reportInfo, setReportInfo] = useState(initialReportInfo);

	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);

	const lottieRef	= useRef();

	useEffect(() => {
		dispatch(get_organizations());
		dispatch(get_all_apps());
	}, []);

	useEffect(() => {
		if (create) {
			setReportInfo(initialReportInfo);
			setOrgFilters(filters);
		}
		else {
			setOrgFilters({ organization: updateReport?.organization?.$oid || '', workspace: updateReport?.workspace?.$oid || '', module: updateReport?.module?.$oid || '' });
			setReportInfo({ ...updateReport, app: updateReport?.app?.$oid || '' });
		}
	}, [create, updateReport]);

	useEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}
	}, [orgFilters]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
		}

		if (e !== null){
			filters[name] = e.value;
		}

		setOrgFilters(filters);
	}

	const closeModal = () => {
		onHide();
		setAlert(initialAlert);
		// setOrganization('');
		setReportInfo(initialReportInfo);
	}

	const createOrganizationOptions = (organizations) => {
		let orgOptions = [];

		organizations.forEach((org) => {
			orgOptions.push({ label: org.name, value: org._id.$oid });
		});

		return orgOptions;
	}

	const createAppsOptions = (all_apps) => {
		let appsOptions = [];

		if (!errorExists(apps_errors, 'all_apps')) {
			all_apps?.apps.forEach((app) => {
				appsOptions.push({ label: app.name, value: app._id.$oid });
			});
		}

		return appsOptions;
	}

	const validateReportInfo = reportInfo.app === '' || reportInfo.alias === '' || reportInfo.name === '' || reportInfo.description === '' || reportInfo.action === '' || reportInfo.create_tab === '';

	const reportsHandler = (e) => {
		e.preventDefault();

		if (create) {
			if (isEmpty(orgFilters) || validateReportInfo) {
				setAlert({ display: true, type: 'danger', msg: t('reportModal.errorAlertMsg') });
				return;
			}
			setAlert(initialAlert);
			dispatch(create_report({ ...orgFilters, ...reportInfo }, skip, limit, closeModal));
		}
		else {
			if (validateReportInfo) {
				setAlert({ display: true, type: 'danger', msg: t('reportModal.errorAlertMsg') });
				return;
			}
			setAlert(initialAlert);

			dispatch(update_report(reportInfo, skip, limit, closeModal));
		}
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{create ? t('reportModal.type.createTitle') : t('reportModal.type.updateTitle')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display && <Alert type={alert.type} msg={alert.msg} />}

				{errorExists(reports_errors, 'create_report') &&
					<Alert type='danger' msg={reports_errors['create_report'].msg} />
				}

				{(create && filters.organization === '') &&
					<Container className='mb-3 p-0' fluid>
						<Form.Label>{t('landing.orgFilters.organization')}</Form.Label>
						<Select
							classNamePrefix='select'
							placeholder={t('selectButton.placeholder')}
							noOptionsMessage={() => t('selectButton.noOptionsMessage')}
							maxMenuHeight={170}
							options={createOrganizationOptions(organizations)}
							value={orgFilters.organization !== '' ? createOrganizationOptions(organizations).find(opt => opt.value === orgFilters.organization) : null}
							onChange={(e) => onChangeOrgFilters(e, 'organization')}
							isDisabled={false}
							isClearable={true}
							isSearchable={true}
						/>
					</Container>
				}
				{(create && filters.workspace === '') &&
					<Container className='mb-3 p-0' fluid>
						<Form.Label>{t('landing.orgFilters.workspace')}</Form.Label>
						<Select
							classNamePrefix='select'
							placeholder={t('selectButton.placeholder')}
							noOptionsMessage={() => t('selectButton.noOptionsMessage')}
							maxMenuHeight={170}
							options={createOrganizationOptions(workspaces)}
							value={orgFilters.workspace !== '' ? createOrganizationOptions(workspaces).find(opt => opt.value === orgFilters.workspace) : null}
							onChange={(e) => onChangeOrgFilters(e, 'workspace')}
							isDisabled={false}
							isClearable={true}
							isSearchable={true}
						/>
					</Container>
				}
				{(create && filters.module === '') &&
					<Container className='mb-3 p-0' fluid>
						<Form.Label>{t('overview.orgFilters.module')}</Form.Label>
						<Select
							classNamePrefix='select'
							placeholder={t('selectButton.placeholder')}
							noOptionsMessage={() => t('selectButton.noOptionsMessage')}
							maxMenuHeight={170}
							options={createOrganizationOptions(modules)}
							value={orgFilters.module !== '' ? createOrganizationOptions(modules).find(opt => opt.value === orgFilters.module) : null}
							onChange={(e) => onChangeOrgFilters(e, 'module')}
							isDisabled={false}
							isClearable={true}
							isSearchable={true}
						/>
					</Container>
				}
				{create &&
					<Container className='mb-3 p-0' fluid>
						<Form.Label>Dashboard</Form.Label>
						<Select
							classNamePrefix='select'
							placeholder={t('selectButton.placeholder')}
							noOptionsMessage={() => t('selectButton.noOptionsMessage')}
							maxMenuHeight={170}
							options={createAppsOptions(all_apps)}
							name='app'
							value={reportInfo.app !== '' ? createAppsOptions(all_apps).find(opt => opt.value === reportInfo.app) : null}
							onChange={(e) => setReportInfo({ ...reportInfo, app: e !== null ? e.value : '' })}
							isDisabled={false}
							isClearable={true}
							isSearchable={true}
							isLoading={all_apps_loading}
						/>
					</Container>
				}
				<Form>
					<FloatingLabel className='mb-3' controlId='alias' label='Alias'>
						<Form.Control type='text' placeholder='Alias' value={reportInfo.alias || ''}
							onChange={(e) => setReportInfo({...reportInfo, alias: e.target.value})}
						/>
					</FloatingLabel>
					<FloatingLabel className='mb-3' controlId='name' label={t('reportModal.form.name')}>
						<Form.Control type='text' placeholder='Name' value={reportInfo.name || ''}
							onChange={(e) => setReportInfo({...reportInfo, name: e.target.value})}
						/>
					</FloatingLabel>
					<FloatingLabel className='mb-3' controlId='description' label={t('reportModal.form.description')}>
						<Form.Control type='text' placeholder='Description' value={reportInfo.description || ''}
							onChange={(e) => setReportInfo({...reportInfo, description: e.target.value})}
						/>
					</FloatingLabel>
					<FloatingLabel className='mb-3' controlId='action' label={t('reportModal.form.action')}>
						<Form.Control type='text' placeholder='Action' value={reportInfo.action || ''}
							onChange={(e) => setReportInfo({...reportInfo, action: e.target.value})}
						/>
					</FloatingLabel>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{t('reportModal.form.createTab')}</Form.Label>
						<div>
							<Form.Check
								inline
								label={t('reportModal.form.createTabOptions.true')}
								type='radio'
								checked={reportInfo.create_tab === true}
								onChange={() => setReportInfo({...reportInfo, create_tab: true})}
							/>
							<Form.Check
								inline
								label={t('reportModal.form.createTabOptions.false')}
								type='radio'
								checked={reportInfo.create_tab === false}
								onChange={() => setReportInfo({...reportInfo, create_tab: false})}
							/>
						</div>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='outline-secondary' onClick={closeModal}>{t('reportModal.form.buttons.cancel')}</Button>
				<Button className={create_report_success || update_report_success ? 'success-btn' : 'submit-btn'}
					onClick={(e) => reportsHandler(e)}
				>
					{create_report_success || update_report_success
						? <Lottie
								loop={false}
								lottieRef={lottieRef}
								animationData={checkAnimation}
								style={{ height: '24px' }}
							/>
						: update_report_success
							? <Fragment>
									<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
									{create ? t('reportModal.form.buttons.creating') : t('reportModal.form.buttons.editing')}
								</Fragment>
							: create ? t('reportModal.form.buttons.create') : t('reportModal.form.buttons.edit')
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

ReportModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	create: PropTypes.bool.isRequired,
	filters: PropTypes.object.isRequired,
	updateReport: PropTypes.object,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired
}

export default ReportModal;
