import React from 'react';

// Packages
import { BrowserRouter as  Router, Route, Switch, Redirect }  from 'react-router-dom';

// Components
import Navbar from './components/main/Navbar';
import Footer from './components/main/Footer';
import Authentication from './components/main/Authentication';
import NotFound from './components/main/NotFound';
import PrivateRoute from './router/PrivateRoute';
import Overview from './components/overview/Overview';
import Counters from './components/counters/Counters';
import Reports from './components/reports/Reports';
import ReportInfo from './components/reports/ReportInfo';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { user_token_check } from './actions/authActions';

user_token_check (store);

const App = () => {
	return (
		<Provider store= { store }>
			<Router>
				<div className="App">
					<Navbar/>

					<Switch>
						{/* Overview */}
						<Route exact path='/overview' component={ Overview } />

						{/* Counters */}
						<Route exact path='/counters' component={ Counters } />

						{/* Reports */}
						<Route exact path='/reports' component={ Reports } />
						<Route exact path='/reports/:report_id' component={ ReportInfo } />

						<Route exact path='/auth' component={ Authentication } />

						{/* Other */}
						<Route exact path='/404' component={ NotFound } />
						
						<Redirect to="/404" />
					</Switch>
					
					<Footer/>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
