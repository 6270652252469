import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Container, Col, Row, Card, Table } from 'react-bootstrap';

// Packages
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';

// Components
import ReportLogData from './ReportLogData';
import TablePagination from '../utils/TablePagination';
import StatusBadge from '../utils/StatusBadge';

// Actions
import { get_report_info, get_all_report_logs } from '../../actions/reportsActions';
import { get_all_apps } from '../../actions/appsActions';

// Utils
import { epochToLocalDate } from '../../utils/datesHandler';
import { getReportStatusDesc } from '../../utils/getStatusDesc';
import isEmpty from '../../utils/isEmpty';

const ReportInfo = () => {
	const { report_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_reports_loading, report_info, all_report_logs } = useSelector(state => state.reports);

	const { all_apps } = useSelector(state => state.apps);

	const [showLogData, setShowLogData] = useState(false);
	const [selectedLog, setSelectedLog] = useState({});
	const [skip, setSkip] = useState(0);
	const [limit] = useState(10);
	
	useEffect(() => {
		dispatch(get_report_info(report_id));
		dispatch(get_all_report_logs({ report: report_id, skip, limit }));
		dispatch(get_all_apps());
	}, []);

	useEffect(() => {
		dispatch(get_all_report_logs({ report: report_id, skip, limit }));
	}, [skip]);

	const selectReport = (logId) => {
		let logFound = {};
		
		if (!isEmpty(selectedLog)) {
			if (logId !== selectedLog._id.$oid) {
				logFound = all_report_logs.logs.find(log => log._id.$oid === logId);
				if (isEmpty(logFound.data)) {
					logFound.data = { data: 'No data' }
				}
				setSelectedLog(logFound);
				setShowLogData(true);
			}
			else {
				setShowLogData(false);
				setSelectedLog({});
			}
		}
		else {
			logFound = all_report_logs.logs.find(log => log._id.$oid === logId);
			if (isEmpty(logFound.data)) {
				logFound.data = { data: 'No data' }
			}
			setSelectedLog(logFound);
			setShowLogData(true);
		}
	}

	return (
		<Container className='mt-3 custom-p-container'>
			<h2 className='text-center'>{report_info?.name}</h2>
			<p className='text-center text-muted'>{report_info?.description}</p>

			<Card className='shadow mb-5 bg-body rounded card-border'>
				<Card.Body>
					<Card.Title className='text-center' as='h5'>{t('reportInfo.title')}</Card.Title>
					<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {report_info?._id?.$oid}</Card.Subtitle>
					<hr/>
					<Container className='p-0'>
						<Row>
							<Col>
								<p className='text-center m-0 p-0'><strong>{t('landing.orgFilters.organization')}:</strong> {report_info?.organization?.name}</p>
							</Col>
							<Col>
								<p className='text-center m-0 p-0'><strong>{t('landing.orgFilters.workspace')}:</strong> {report_info?.workspace?.name}</p>
							</Col>
							<Col>
								<p className='text-center m-0 p-0'><strong>{t('overview.orgFilters.module')}:</strong> {report_info?.module?.name}</p>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Col>
								<p className='text-center m-0 p-0'><strong>Alias:</strong> {report_info?.alias}</p>
								<p className='text-center m-0 p-0'><strong>{t('reportInfo.date')}:</strong> {epochToLocalDate(report_info?.date?.$date)}</p>
							</Col>
							<Col>
								<p className='text-center m-0 p-0'><strong>{t('reportInfo.status')}:</strong> <StatusBadge status={getReportStatusDesc(report_info?.status)} /></p>
								<p className='text-center m-0 p-0'>
									<strong>{t('reportModal.form.createTab')}:</strong> {report_info?.create_tab ? t('reportModal.form.createTabOptions.true') : t('reportModal.form.createTabOptions.false')}
								</p>
							</Col>
						</Row>
						<hr/>
						<Col>
							<p className='text-center m-0 p-0'>
								<i className='bi bi-laptop me-2'></i> {all_apps.apps.find(app => app._id.$oid === report_info?.app?.$oid)?.name}
							</p>
							<hr/>
							<p className='text-center m-0 p-0'><strong>{t('reportModal.form.action')}:</strong> {report_info?.action}</p>
						</Col>
					</Container>
				</Card.Body>
			</Card>

			<Card className='shadow mb-5 bg-body rounded card-border'>
				<Card.Body>
					<Card.Title className='text-center' as='h5'>Logs</Card.Title>
					<Table responsive>
						<thead className='table-head'>
							<tr>
								<th>Id</th>
								<th>{t('reportInfo.logsTable.headers.user')}</th>
								<th>{t('reportInfo.logsTable.headers.completed')}</th>
								<th>{t('reportInfo.date')}</th>
							</tr>
						</thead>
						<tbody>
							{all_reports_loading 
								?	<tr>
										<td className='text-center' colSpan={4}>
											<ClipLoader color='#004982' size={40} speedMultiplier={1} cssOverride={{ 'borderWidth': 'thick' }} />
										</td>
									</tr>
								:	all_report_logs.count > 0
									?	all_report_logs.logs.map((log) => (
											<Fragment key={log._id.$oid}>
												<tr style={{ cursor: 'pointer' }} onClick={() => selectReport(log._id.$oid)}>
													<td>{log._id.$oid}</td>
													<td>{log.user.username}</td>
													<td>{log.completed}</td>
													<td>{epochToLocalDate(log.date.$date)}</td>
												</tr>
												{showLogData && log._id.$oid === selectedLog._id.$oid
													?	<tr>
															<td colSpan={4}>
																<Container>
																	<ReportLogData logData={log.data} />
																</Container>
															</td>
														</tr>
													: null
												}
											</Fragment>
										))
									:	<tr>
											<td className='text-center' colSpan={4}>{t('reportInfo.logsTable.errors.noLogs')}</td>
										</tr>
							}
						</tbody>
					</Table>
					<TablePagination
						count={all_report_logs.count}
						arrayLength={all_report_logs.logs.length}
						nPages={Math.ceil(all_report_logs.count / limit)}
						skip={skip}
						setSkip={setSkip}
						limit={limit}
					/>
				</Card.Body>
			</Card>
		</Container>
	)
}

export default ReportInfo;
