import i18next from 'i18next';

// CYCLE_STATUS_NONE = 0
// CYCLE_STATUS_CREATED = 1
// CYCLE_STATUS_STARTING = 2
// CYCLE_STATUS_AVAILABLE = 3
// CYCLE_STATUS_WAITING = 4
// CYCLE_STATUS_COMPLETED = 5
// CYCLE_STATUS_REMOVED = 6
const getCycleStatusDesc = (status = 0) => {
	let desc = '';

	switch (status) {
		case 0:		desc = i18next.t('statusDesc.none');				break;
		case 1:		desc = i18next.t('statusDesc.created');			break;
		case 2:		desc = i18next.t('statusDesc.starting');		break;
		case 3:		desc = i18next.t('statusDesc.available');		break;
		case 4:		desc = i18next.t('statusDesc.waiting');			break;
		case 5:		desc = i18next.t('statusDesc.completed');		break;
		case 6:		desc = i18next.t('statusDesc.removed');			break;
		default:	desc = i18next.t('statusDesc.none');				break;
	}

	return desc;
}

// REPORT_STATUS_NONE = 0
// REPORT_STATUS_CREATED = 1
// REPORT_STATUS_AVAILABLE = 2
// REPORT_STATUS_DEPRECATED = 3
// REPORT_STATUS_REMOVED = 4
const getReportStatusDesc = (status = 0) => {
	let desc = '';

	switch (status) {
		case 0:		desc = i18next.t('statusDesc.none');				break;
		case 1:		desc = i18next.t('statusDesc.created');			break;
		case 2:		desc = i18next.t('statusDesc.available');		break;
		case 3:		desc = i18next.t('statusDesc.deprecated');	break;
		case 4:		desc = i18next.t('statusDesc.removed');			break;
		default:	desc = '';																	break;
	}

	return desc;
}

export { getCycleStatusDesc, getReportStatusDesc };
