const getColorContrast = (color = '') => {
	const _hexcolor = color;

	let r = parseInt(_hexcolor?.substring(1, 3), 16);
	let g = parseInt(_hexcolor?.substring(3, 5), 16);
	let b = parseInt(_hexcolor?.substring(5, 7), 16);

	let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	return (yiq >= 186) ? 'black' : 'white';
}

export { getColorContrast };
