import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ReactSpeedometer from 'react-d3-speedometer';
import { useTranslation } from 'react-i18next';

// Utils
import isEmpty from '../../utils/isEmpty';

ChartJS.register(ArcElement, Tooltip, Legend);

const Progress = (props) => {
	const { progressFilters, setProgressFilters } = props;

	const { t } = useTranslation();

	const _ls = { ...localStorage };

	const { progress, tables } = useSelector(state => state.overview);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom',
			},
			tooltip: false,
			labels: {
				render: 'value'
			}
		},
		maintainAspectRatio: false,
		hover: { mode: null }
	}

	const data = {
		labels: [
			`${(progress.total_available - (progress.total_work + progress.total_issues)).toLocaleString(_ls.i18nextLng)} - ${t('progress.pie.available')}`,
			`${(progress.total_work).toLocaleString(_ls.i18nextLng)} - ${t('progress.pie.work')}`,
			`${(progress.total_issues).toLocaleString(_ls.i18nextLng)} - ${t('progress.pie.issues')}`
		],
		datasets: [
			{
				data: [progress.total_available - (progress.total_work + progress.total_issues), progress.total_work, progress.total_issues],
				backgroundColor: [
					'#81c995', // green
					'#8ab4f8', // blue
					'#babab6', // gray
				]
			},
		],
	};

	const isOptionSelected = (id) => {
		let isSelected = false;

		Object.keys(progressFilters).forEach((key) => {
			Object.entries(progressFilters[key]).forEach(([k, value]) => {
				if (value === id) {
					isSelected = true;
				}
			})
		});

		return isSelected;
	}

	const selectChildOption = (e, child, child_value) => {
		const isParentEmpty = isEmpty(progressFilters.parent);
		const isChildEmpty = isEmpty(progressFilters.child);

		if (isOptionSelected(child_value)) { // is selected
			// remove from progressFilters
			if (progressFilters.parent.hasOwnProperty('child_tables')) { // parent has child_tables
				// child_value is in parent
				setProgressFilters({
					...progressFilters,
					parent: {}
				});
			}
			else {
				// child_value is in child
				setProgressFilters({
					...progressFilters,
					child: {}
				});
			}
		}
		else {
			// add to progressFilters
			if (isParentEmpty) { // parent is empty
				if (isChildEmpty) { // child is empty
					// child is parent
					setProgressFilters({
						parent: { option: child, option_value: child_value, child_tables: true },
						child: {}
					});
				}
			}
			else { // parent is not empty
				if (progressFilters.parent.hasOwnProperty('child_tables')) { // parent has child_tables
					// update parent with new option
					setProgressFilters({
						parent: { option: child, option_value: child_value, child_tables: true },
						child: {}
					});
				}
				else {
					// update child with new option
					setProgressFilters({
						...progressFilters,
						child: { child: child, child_value: child_value }
					});
				}
			}
		}
	}

	return ( 
		<div className='container-fluid mt-5 mb-4'>
			<div className='d-flex row justify-content-center comp-spacing'>
				{tables.length > 0 &&
					tables.map((progress, idx) => (
						<div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center' key={idx}>
							<table className='table table-striped text-center'>
								<thead style={{backgroundColor:'#214C8E', color:'white'}}>
									<tr>
										<th style={{border:'none'}} colSpan='4'>{t('progress.progressByTable')} {progress.name}</th>
									</tr>
								</thead>  
								<tbody>
									{progress.values.length > 0
										? progress.values.map((value, vIdx) => (
												<tr className='text-center' key={vIdx}>
													<td>
														<button type='button' className={isOptionSelected(value._id.$oid) ? 'is-filter' : 'not-filter'}
															onClick={(e) => {selectChildOption(e, value.option.$oid, value._id.$oid);}}
														>
															{value.name}
														</button>
													</td>
													<td>
														{value.data !== null
															? value.data.available === 0 ? '0.00%' : ((value.data?.work / value.data?.available) * 100).toFixed(2) + '%'
															: '0.00%'
														}
													</td>
												</tr>
											))
										: <tr className='text-center'>
												<td colSpan={2}>{t('progress.progressByError')}</td>
											</tr>
									}
								</tbody>
							</table>
						</div>
					))
				}
			</div>
			<div className='d-flex row justify-content-center'>
				<div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center comp-spacing'>
					<h5>{t('progress.pie.title')}</h5>
					<div className='doughnut'>
						<Doughnut options={ options } data={ data } />
					</div>
				</div>
				<div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center comp-spacing'>
					<h5>{t('progress.workSpeedometer.title')}</h5>
					<ReactSpeedometer
						height={200}
						forceRender={true}
						maxSegmentLabels={1}
						customSegmentStops={[0, 90, 95, 100]}
						segmentColors={['#519867', '#81c995', '#b2fcc6']}
						needleColor={'black'}
						currentValueText={'${value}%'}
						minValue={0}
						maxValue={100}
						value={progress.total_available !== 0 
							? parseFloat(((progress.total_work / progress.total_available) * 100).toFixed(2)) : 0
						}
						valueTextFontSize={'33px'}
						textColor={'#00000'}
						paddingVertical={17}
					/>
					<h1>{progress?.total_work.toLocaleString(_ls.i18nextLng)} / {progress?.total_available.toLocaleString(_ls.i18nextLng)}</h1>
					<h5 className='text-secondary'>{t('progress.workSpeedometer.type')}</h5>
				</div>
				<div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center comp-spacing'>
					<h5>{t('progress.issuesSpeedometer.title')}</h5>
					<ReactSpeedometer
						height={200}
						forceRender={true}
						maxSegmentLabels={1}
						customSegmentStops={[0, 5, 10, 50]}
						segmentColors={['#bce5ff', '#88b3f7', '#5584c4']}
						needleColor={'black'}
						currentValueText={'${value}%'}
						minValue={0}
						maxValue={50}
						value={progress.total_available !== 0 
							? parseFloat(((progress.total_issues / progress.total_available) * 100).toFixed(2)) : 0
						}
						valueTextFontSize={'33px'}
						textColor={'#00000'}
						paddingVertical={17}
					/>
					<h1>{progress?.total_issues.toLocaleString(_ls.i18nextLng)}</h1>
					<h5 className='text-secondary'>{t('progress.issuesSpeedometer.type')}</h5>
				</div>
			</div>
		</div>
	);
}

Progress.propTypes = {
	progressFilters: PropTypes.object.isRequired,
	setProgressFilters: PropTypes.func.isRequired
};

export default Progress;
