import React, { useState, useEffect, Fragment } from 'react';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Utils
import { epochToLocalDate, cycleDaysInfo } from '../../utils/datesHandler';

const CycleDatesInfo = (props) => {
	const { cycle } = props;

	const { t } = useTranslation();

	const [daysInfo, setDaysInfo] = useState({ workingDays: 0, currentDays: 0, remainingDays: 0 });

	useEffect(() => {
		const [workingDays, currentDays, remainingDays] = cycleDaysInfo(cycle.start.$date, cycle.end.$date);

		setDaysInfo({
			...daysInfo,
			workingDays: workingDays,
			currentDays: currentDays,
			remainingDays: remainingDays
		});
	}, [cycle]);

	return (
		<div className='text-center fs-5 mb-5'>
			<p>
				<b>{t('cycle.start')}</b> {epochToLocalDate(cycle.start.$date, localStorage.getItem('i18nextLng'))} - 
				<b>{t('cycle.end')}</b> {epochToLocalDate(cycle.end.$date, localStorage.getItem('i18nextLng'))}
			</p>

			<p>
				<b>{t('cycle.workingDays')}</b> {daysInfo.workingDays}
				{cycle.status !== 5 &&
					<Fragment>
						&nbsp;- <b>{t('cycle.currentDays')}</b> {daysInfo.currentDays} - <b>{t('cycle.remainingDays')}</b> {daysInfo.remainingDays}
					</Fragment>
				}
			</p>
		</div>
	);
}

CycleDatesInfo.propTypes = {
	cycle: PropTypes.object.isRequired
};

export default CycleDatesInfo;
