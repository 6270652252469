import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import overviewReducer from './overviewReducer';
import countersReducer from './countersReducer';
import reportsReducer from './reportsReducer';
import appsReducer from './appsReducer';

export default combineReducers ({
	errors: errorReducer,
	auth: authReducer,
	organization: organizationReducer,
	overview: overviewReducer,
	counters: countersReducer,
	reports: reportsReducer,
	apps: appsReducer
});
