import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';

// Actions
import { download_report } from '../../actions/reportsActions';

// Styles
import { tableStyles } from '../../styles/index';

// Utils
import errorExists from '../../utils/errorExists';
import { cycleDaysInfo } from '../../utils/datesHandler';

const TableData = (props) => {
	const { cycle, orgFilters, areaFilters, areaLabels, progressFilters, org_reports } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };
	
	const { progress } = useSelector(state => state.overview);

	const { download_report_loading, reports_errors } = useSelector(state => state.reports);

	const [daysInfo, setDaysInfo] = useState({ workingDays: 0, currentDays: 0, remainingDays: 0 });

	useEffect(() => {
		const [workingDays, currentDays, remainingDays] = cycleDaysInfo(cycle.start.$date, cycle.end.$date);

		setDaysInfo({
			...daysInfo,
			workingDays: workingDays,
			currentDays: currentDays,
			remainingDays: remainingDays
		});
	}, [cycle]);

	const getDailyPayloads = (notWorked = 0) => {
		let censusPerDay = 0;
		
		if (cycle?.status !== 5) {
			if (daysInfo.remainingDays <= 0) censusPerDay = notWorked;
			else censusPerDay = notWorked / daysInfo.remainingDays;

			let floor = Math.floor(censusPerDay);
			if (floor * daysInfo.remainingDays >= notWorked) censusPerDay = floor;
			else censusPerDay = Math.ceil(censusPerDay);
		}
		
		return censusPerDay.toLocaleString(_ls.i18nextLng);
	}

	return (
		<div className='mt-5 mb-5'>
			<Table responsive>
				<thead className='align-middle text-center'>
					<tr style={{ height: '60px' }}>
						<th colSpan='5' style={tableStyles.issues}></th>
						<th colSpan='6' style={tableStyles.issuesHeader}>{t('progressTable.issuesTypes.header')}</th>
						<th colSpan='8' style={tableStyles.issues}></th>
					</tr>
					<tr style={{ height: '105px' }}>
						<th style={tableStyles.cellText}>{t('progressTable.area')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.available')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.work')}</th>
						<th style={tableStyles.percentage}>{t('progressTable.workPercentage')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.visits')}</th>
						{/* Issues */}
						<th style={tableStyles.cellText}>{t('progressTable.issuesTypes.closed')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.issuesTypes.closedDown')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.issuesTypes.access')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.issuesTypes.process')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.issuesTypes.products')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.issuesTypes.custom')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.issues')}</th>
						<th style={tableStyles.percentage}>{t('progressTable.issuesPercentage')}</th>
						{/* End Issues */}
						<th style={tableStyles.cellText}>{t('progressTable.notWorked')}</th>
						<th style={tableStyles.percentage}>{t('progressTable.notWorkedPercentage')}</th>
						<th style={tableStyles.cellText}>{t('progressTable.total')}</th>
						<th style={tableStyles.percentage}>{t('progressTable.totalPercentage')}</th>
						{cycle.status !== 5 &&
							<th style={tableStyles.cellText}>{t('progressTable.dailyPayloads')}</th>
						}
						<th style={tableStyles.cellText}>{t('progressTable.scoreAverage')}</th>
					</tr>
				</thead>
				<tbody className='align-middle text-center'>
					{progress?.data?.length === 0
						?	<tr style={{ height: '53px' }}>
								<td colSpan='17'>{t('progressTable.error')}</td>
							</tr>
						:	progress?.data.map((row, i) => (
								<tr key={i} style={{ backgroundColor: row?.name === 'TOTAL' ? '#e0e0e0': '#ffffff', height: '53px' }}>
									<td>{row?.name}</td>
									<td>{row?.available.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.work.toLocaleString(_ls.i18nextLng)}</td>
									<td>
										{row?.available === 0 ? '0.00%' : ((row?.work / row?.available) * 100).toFixed(2) + '%'}
									</td>
									<td>{row?.visits ? row?.visits : '-'}</td>
									<td>{row?.closed.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.closed_down.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.access.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.process.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.products.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.custom.toLocaleString(_ls.i18nextLng)}</td>
									<td>{row?.issues.toLocaleString(_ls.i18nextLng)}</td>
									<td>
										{row?.available === 0 ? '0.00%' : ((row?.issues / row?.available) * 100).toFixed(2) + '%'}
									</td>
									<td>{(row?.available - (row?.work + row?.issues)).toLocaleString(_ls.i18nextLng)}</td>
									<td>
										{(row?.available - (row?.work + row?.issues)) / row?.available === 1 || row?.available === 0 ? '0.00%'
											: (((row?.available - (row?.work + row?.issues)) / row?.available) * 100).toFixed(2) + '%'
										}
									</td>
									<td>{(row?.work + row?.issues).toLocaleString(_ls.i18nextLng)}</td>
									<td>
										{row?.available === 0 ? '0.00%' : (((row?.work + row?.issues) / row?.available) * 100).toFixed(2) + '%'}
									</td>
									{cycle.status !== 5 &&
										<td>{getDailyPayloads(row?.available - (row?.work + row?.issues))}</td>
									}
									<td>{row?.average_score?.toFixed(2)?.toLocaleString(_ls.i18nextLng) || 0}</td>
								</tr>
							))
					}
				</tbody>
				{org_reports.length > 0 && 
					<tfoot style={{ backgroundColor: 'white' }}>
						<tr style={{ height: '70px' }}>
							<td colSpan='19'>
								{org_reports.map((report) => (
									<Button key={report._id.$oid}
										className={'fw-bold me-5 ' + (errorExists(reports_errors, 'download_report') && download_report_loading.report_id === report._id.$oid 
											? 'danger-btn' : 'submit-btn'
										)}
										onClick={() => { dispatch(download_report(report, { orgFilters, areaFilters, areaLabels, progressFilters })) }}
									>
										{download_report_loading.loading && download_report_loading.report_id === report._id.$oid
											?	<div className='d-flex align-items-center justify-content-center' style={{ width: '195px' }}>
													<ClipLoader color='#ffffff' size={24} speedMultiplier={1} cssOverride={{ 'borderWidth': 'thick' }} />
												</div>
											:	errorExists(reports_errors, 'download_report') && download_report_loading.report_id === report._id.$oid
												?	<div style={{ width: '195px' }}>
														Error <i className='bi bi-x-lg bi-bold ms-2'></i>
													</div>
												:	<div>
														{t('reports.download')} {report.name} <i className='bi bi-download bi-bold-download ms-2'></i>
													</div>
										}
									</Button>
								))}
							</td>
						</tr>
					</tfoot>
				}
			</Table>
		</div>
	);
}

TableData.propTypes = {
	cycle: PropTypes.object.isRequired,
	orgFilters: PropTypes.object.isRequired,
	areaFilters: PropTypes.object.isRequired,
	areaLabels: PropTypes.object.isRequired,
	progressFilters: PropTypes.object.isRequired,
	org_reports: PropTypes.array.isRequired
};

export default TableData;
