import React from 'react';

// Packages
import {  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );

const BarChart = (props) => {
	const { dataset, month } = props;

	const { t } = useTranslation();

	const _ls = { ...localStorage };

	const createDate = (epoch) => {
		let labelDate = '';
		
		if (month) { // grafica del mes
			labelDate = epoch;
		}
		else { // grafica de dias
			let date = new Date(epoch);
			labelDate = `${date.toLocaleString(_ls.i18nextLng, { hour: 'numeric', hour12: !_ls.i18nextLng.includes('es') })} ${_ls.i18nextLng.includes('es') ? 'Hrs.' : ''}`;
		}
		
		return labelDate;
	}
	
	const getBarDataCount = (context) => {
		let labels = [];

		if (context.parsed.y) {
			labels.push(context.parsed.y);

			let payloadsSum = 0;
			for (let index = 0; index <= context.parsed.x && index < dataset.length; index++) {
				const element = dataset[index];
				payloadsSum += element.value;
			}

			labels.push(t('barChart.tooltip') + payloadsSum);
		}
		
		return labels;
	}

	const options = {
		responsive: true,
		plugins: {
			legend: { display: false },
			...(!month && {
				tooltip: {
					callbacks: {
						label: (context) => getBarDataCount(context)
					}
				}
			})
		},
		scales: {
			x: {
				grid: { display: false }
			},
			y: {
				display: false,
				grid: { display: false }
			}
		},
		maintainAspectRatio: false,
	};

	let backgroundColor = Array(dataset.length).fill('#7f9dcc');
	let maxValue = 0, maxIndex = 0;
	
	for (let i = 0; i < dataset.length; i++) {
		if (dataset[i].value > maxValue) {
			maxValue = dataset[i].value;
			maxIndex = i;
		}
	}

	backgroundColor[maxIndex] = '#3e619b';

	const data = {
		labels: dataset.map(element => createDate(element.time)),
		datasets: [
			{
				data: dataset.map(element => Math.trunc(element.value)),
				// backgroundColor: '#3e619b'
				// backgroundColor: '#30457b'
				backgroundColor: backgroundColor
			}
		]
	};

	return (
		<Bar options={ options } data={ data }/>
	);
}

BarChart.propTypes = {
	dataset: PropTypes.array.isRequired,
	month: PropTypes.bool.isRequired
};

export default BarChart;
