import React from 'react';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TablePagination = (props) => {
	const { count, arrayLength, nPages, skip, setSkip, limit } = props;

	const { t } = useTranslation();
	
	const firstPage = () => {
		setSkip(0);
	}

	const previousPage = () => {
		if (skip !== 0) {
			setSkip(prevState => prevState - limit);
		}
	}

	const nextPage = () => {
		if (skip + arrayLength !== count) {
			setSkip(prevState => prevState + limit);
		}
	}

	const lastPage = () => {
		setSkip((nPages - 1) * limit);
	}

	return (
		<nav aria-label='Page navigation example'>
			<ul className='pagination justify-content-end'>
				<li className='page-item d-flex align-items-center'>
					<p className='rows-count my-0 me-3'>
						{count === 0 ? 0 : skip + 1} - {skip + arrayLength} {t('pagination.of')} {count}
					</p>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='First' onClick={firstPage} disabled={skip === 0}>
						<i className='bi bi-chevron-bar-left bi-bold'></i>
					</button>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='Previous' onClick={previousPage} disabled={skip === 0}>
						<i className='bi bi-chevron-left bi-bold'></i>
					</button>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='Next' onClick={nextPage} disabled={skip + arrayLength === count}>
						<i className='bi bi-chevron-right bi-bold'></i>
					</button>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='Last' onClick={lastPage} disabled={skip + arrayLength === count}>
						<i className='bi bi-chevron-bar-right bi-bold'></i>
					</button>
				</li>
			</ul>
		</nav>
	);
}

TablePagination.prototypes = {
	count: PropTypes.number.isRequired,
	arrayLength: PropTypes.number.isRequired,
	nPages: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
}

export default TablePagination;
