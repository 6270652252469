import i18next from 'i18next';
import { getColorContrast } from './colorsHandler';

const setStatusBadgeColor = (status) => {
	let bgColor = '#000000', textColor = getColorContrast(bgColor);

	switch (status) {
		case i18next.t('statusDesc.none'):
			bgColor = '#adb5bd';
			textColor = getColorContrast(bgColor);
		break;
		case i18next.t('statusDesc.created'):
			bgColor = '#0d6efd';
			textColor = getColorContrast(bgColor);
		break;
		case i18next.t('statusDesc.available'):
			bgColor = '#64dd17';
			textColor = getColorContrast(bgColor);
		break;
		case i18next.t('statusDesc.deprecated'):
			bgColor = '#424242';
			textColor = getColorContrast(bgColor);
		break;
		case i18next.t('statusDesc.removed'):
			bgColor = '#ad1457';
			textColor = getColorContrast(bgColor);
		break;
		case i18next.t('statusDesc.waiting'):
			bgColor = '#fbc02d';
			textColor = getColorContrast(bgColor);
		break;

		default:
			bgColor = '#000000';
			textColor = getColorContrast(bgColor);
		break;
	}

	return { bgColor, textColor }
}

export default setStatusBadgeColor;
