import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import BarChart from '../utils/BarChart';

const Graphics = (props) => {
  const { cycle, dayToSearch, setDayToSearch } = props;

  const { t } = useTranslation();
  
  const monthly_graphic_data = useSelector(state => state.overview.monthly_graphic_data);
  const daily_graphic_data = useSelector(state => state.overview.daily_graphic_data);
  const error = useSelector(state => state.overview.error);

  const [today, setToday] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const createDate = (epoch) => {
    let date = new Date(epoch);

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let newDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);

    return newDate;
  }

  useEffect(() => {
    setToday(createDate(new Date().getTime()));
    setMinDate(createDate(cycle?.start?.$date));
    setMaxDate(createDate(cycle?.end?.$date));
  }, [cycle]);

  return ( 
    <div className='container-fluid mt-4 mb-5'>
      <h4 className='text-center mb-4'>{t('graphics.title')}</h4>
      <div className='d-flex row'>
				<div className='col-lg-6 col-md-6 col-xs-12 text-center'>
					<div className='bar-chart'>
						{monthly_graphic_data.length > 0
							? <BarChart dataset={monthly_graphic_data} month={true}/>
							: <div className='d-flex flex-column justify-content-center align-items-center fade-in shadow-sm p-3 rounded'
									style={{backgroundColor: '#e9e9eb', minHeight: '220px'}}
								>
									<i className='bi bi-exclamation-triangle-fill fs-1 text-warning'></i>
									<h2 style={{textAlign: 'center'}}>{t('graphics.dailyGraphicError')}</h2>
								</div>
						}
					</div>
					<h5>{t('graphics.monthlyGraphic')}</h5>
				</div>
				<div className='col-lg-6 col-md-6 col-xs-12 text-center'>
					<div className='bar-chart'>
						{daily_graphic_data.length > 0
							? <BarChart dataset={daily_graphic_data} month={false}/>
							: <div className='d-flex flex-column justify-content-center align-items-center fade-in shadow-sm p-3 rounded'
									style={{backgroundColor: '#e9e9eb', minHeight: '220px'}}
								>
									<i className='bi bi-exclamation-triangle-fill fs-1 text-warning'></i>
									<h2 style={{textAlign: 'center'}}>{t('graphics.dailyGraphicError')}</h2>
								</div>
						}
					</div>
					<div className='d-flex row justify-content-center align-items-center'>
						<h5 className='col-lg-3 col-md-6 col-xs-12 m-0 pe-0'>{t('graphics.dailyGraphic')}</h5>
						<input className='col-lg-2 col-md-6 col-xs-12' type='date' value={dayToSearch}
							min={minDate} max={cycle?.status === 5 ? maxDate : today}
							onChange={(e) => setDayToSearch(e.target.value)}
						/>
					</div>
				</div>
			</div>
    </div>
  );
}

Graphics.propTypes = {
  cycle: PropTypes.object.isRequired,
  dayToSearch: PropTypes.string.isRequired,
  setDayToSearch: PropTypes.func.isRequired
};
 
export default Graphics;
