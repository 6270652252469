import {
	COUNTERS_LOADING_TRUE,
	COUNTERS_LOADING_FALSE,
	COUNTERS_DATA_GET,
	COUNTERS_DATA_GET_ERROR
} from '../actions/types';

const initialState = {
	counters_loading: false,
	counters_data: { count: 0, items: [], photos: [] },
	counters_errors: {}
}

export default function countersReducer(state = initialState, action){
	switch(action.type){
		case COUNTERS_LOADING_TRUE:
			return {
				...state,
				counters_loading: true
			}
		case COUNTERS_LOADING_FALSE:
			return {
				...state,
				counters_loading: false
			}
		case COUNTERS_DATA_GET:
			delete state.counters_errors.counters_data
			return {
				...state,
				counters_data: {
					count: action.payload.items.length + action.payload.photos.length,
					items: action.payload.items,
					photos: action.payload.photos
				}
			}
		case COUNTERS_DATA_GET_ERROR:
			return {
				...state,
				counters_errors: {
					...state.counters_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
