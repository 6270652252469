export const tableStyles = {
  table: {
    minWidth: 500,
  },
  cellText: {
    backgroundColor: '#415577',
    color: 'white',
    fontWeight: 'bold',
  },
  percentage: {
    backgroundColor: '#9b9b9b',
    color: 'white',
    fontWeight: 'bold',
  },
  issues: {
    backgroundColor: '#e9e9eb',
  },
  issuesHeader: {
    backgroundColor: '#e9e9eb',
    color: 'black',
    fontWeight: 'bold',
    borderTop: '1px solid black',
    borderRight: '1px solid black',
    borderLeft: '1px solid black'
  },
  button: {
    color: 'white',
    fontWeight: 'bold',
    minWidth: '190px'
  },
  spinner: {
    color: '#FFFFFF'
  }
};
