import Axios from 'axios';

import {
	ALL_APPS_LOADING,
	ALL_APPS_GET,
	ALL_APPS_GET_ERROR
} from './types';

export const get_all_apps = () => dispatch => {
	dispatch({
		type: ALL_APPS_LOADING,
		payload: true
	})
	
	let url = process.env.REACT_APP_SERVER_URL + '/api/apps?type=2';

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: ALL_APPS_GET,
			payload: res.data
		})
	}).catch((err) => {
		dispatch({
			type: ALL_APPS_GET_ERROR,
			payload: {type: 'all_apps', msg: err.message}
		})
	})
}
