import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Actions
import { auth_token_remove } from '../../actions/authActions';
import { get_organization_info } from '../../actions/organizationActions';

const Navbar = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organization_info } = useSelector(state => state.organization);

	useEffect(() => {
		dispatch(get_organization_info(user.organization));
	}, []);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(auth_token_remove());
	}

	return (
		<nav className='navbar navbar-expand-lg navbar-dark'>
			<div className='container-fluid'>
				<a className='navbar-brand' href='/overview'>
					<img className='d-inline-block align-items-center me-2' height={'30px'} alt='Percepthor Logo'
						src={process.env.PUBLIC_URL + '/assets/percepthor-logo.png'}
					/>
					Percepthor - {organization_info.name}
				</a>
				<button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNavDropdown' aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle navigation'>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarNavDropdown'>
					<ul className='navbar-nav'>
						<li className='nav-item'>
							<a className={clsx(`nav-link`, { 'active': window.location.pathname.includes('/overview') })} aria-current='page' href='/overview'>
								Overview
							</a>
						</li>
						<li className='nav-item'>
							<a className={clsx(`nav-link`, { 'active': window.location.pathname.includes('/counters') })} aria-current='page' href='/counters'>
								{t('counters.title')}
							</a>
						</li>
						{user.role === '6169a0c1283fbe31af55ab82' &&
							<li className='nav-item'>
								<a className={clsx(`nav-link`, { 'active': window.location.pathname.includes('/reports') })} aria-current='page' href='/reports'>
								{t('reports.title')}
								</a>
							</li>
						}
					</ul>
					<ul className='navbar-nav ms-auto'>
						<li className='nav-item dropdown'>
							<a className='nav-link dropdown-toggle active d-flex align-items-center' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								<i className='bi bi-person-circle fs-5 me-2'></i>{user.username}
							</a>
							<ul className='dropdown-menu dropdown-menu-end' aria-labelledby='navbarDropdown'>
								<li>
									<a className='dropdown-item' href={process.env.REACT_APP_LOGIN + '/account'}>
										<i className='bi bi bi-person-circle me-2'></i>{t('menu.account')}
									</a>
								</li>
								<li>
									<a className='dropdown-item' href={process.env.REACT_APP_LOGIN + '/links'}>
										<i className='bi bi-grid me-2'></i>{t('menu.return')}
									</a>
								</li>
								<li><hr className='dropdown-divider'/></li>
								<li>
									<a className='dropdown-item' style={{cursor: 'pointer'}} onClick={(e) => handleLogout(e)}>
										<i className='bi bi-box-arrow-right me-2'></i>{t('menu.logOut')}
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);

}

export default Navbar;
