import React, { useState, useEffect } from 'react';

// Packages
import clsx from 'clsx';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SelectButton = (props) => {
  const { label, options, name, value, onChange, loading, disable, icon, orgFilter, optFilter } = props;

  const { t } = useTranslation();

  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    let optsArray = [];

    options.forEach((opt) => {
			if (opt === null || opt === undefined) return;

      let obj = {};
      obj.value = opt._id !== undefined ? opt._id.$oid : opt.value;
      obj.label = name !== 'submodule' ? opt.name : opt.title;
      optsArray.push(obj);
    });

    if (!orgFilter && !optFilter) {
      const groupedOptions = [
        {
          label: `${t(`select.${name}`)} ${optsArray.length}`,
          options: optsArray
        }
      ];
      setSelectOptions(groupedOptions);
    }
    else {
      setSelectOptions(optsArray);
    }

  }, [options]);

  return (
    <div className={clsx('mb-2', {
      'col-lg-2 col-md-12 col-sm-12': !orgFilter,
      'col-lg-4 col-md-12 col-sm-12': orgFilter || optFilter,
    })}>
      <div className='d-flex row align-items-center'>
        <div className={clsx('text-center', {
          'col-lg-3 col-md-3 col-sm-12': !orgFilter,
          'col-lg-12 col-md-12 col-sm-12': orgFilter
        })}>
          <i className={`bi bi-${icon}`}></i>
          <label htmlFor='staticEmail2'>&nbsp;&nbsp;{label}</label>
        </div>
        
        <div className={clsx({
          'col-lg-9 col-md-9 col-sm-12': !orgFilter,
          'col-lg-12 col-md-12 col-sm-12': orgFilter
        })}>
          <Select
            classNamePrefix='select'
            placeholder={t('selectButton.placeholder')}
            noOptionsMessage={() => t('selectButton.noOptionsMessage')}
            maxMenuHeight={170}
            options={selectOptions}
            name={name}
            value={value !== null ? selectOptions.find(opt => opt.value === value) : null}
            onChange={(e) => onChange(e, name)}
            isDisabled={disable}
            isClearable={true}
            isSearchable={true}
          />
        </div>
      </div>
    </div>
  );
}

SelectButton.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disable: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  orgFilter: PropTypes.bool.isRequired,
  optFilter: PropTypes.bool
};

export default SelectButton;