import {
  ORGANIZATIONS_GET,
  ORGANIZATIONS_ERROR,
  ORG_INFO_GET,
  ORG_INFO_ERROR,
  WORKSPACES_GET,
  WORKSPACES_ERROR,
  MODULES_GET,
  MODULES_ERROR,
  SUBMODULES_GET,
  SUBMODULES_ERROR,
  CYCLES_GET,
  CYCLES_ERROR,
  AREAS_GET,
  AREAS_ERROR,
  ORG_FILTERS_SET,
  PROGRESS_OPTIONS_GET,
  PROGRESS_OPTIONS_ERROR
} from '../actions/types';

const initialState = {
  organizations: [],
  organization_info: {},
  workspaces: [],
  modules: [],
  submodules: [],
  cycles: [],
  areas: [],
  orgFilters: { module: '', cycle: '', region: '', zone: '', depot: '', group: '' },
  progress_options: [],
  error: { isError: false, type: '', msg: '' }
}

export default function organizationReducer(state = initialState, action){
  switch(action.type){
    case ORGANIZATIONS_GET:
      return {
        ...state,
        organizations: action.payload
      }
    case ORG_INFO_GET:
      return {
        ...state,
        organization_info: action.payload
      }
    case WORKSPACES_GET:
      return {
        ...state,
        workspaces: action.payload
      }
    case MODULES_GET:
      return {
        ...state,
        modules: action.payload
      }
    case SUBMODULES_GET:
      return {
        ...state,
        submodules: action.payload
      }
    case CYCLES_GET:
      return {
        ...state,
        cycles: action.payload
      }
    case AREAS_GET:
      return {
        ...state,
        areas: action.payload
      }
    case ORG_FILTERS_SET:
      return {
        ...state,
        orgFilters: action.payload
      }
    case PROGRESS_OPTIONS_GET:
      return {
        ...state,
        progress_options: action.payload
      }
    case ORGANIZATIONS_ERROR:
    case ORG_INFO_ERROR:
    case WORKSPACES_ERROR:
    case MODULES_ERROR:
    case SUBMODULES_ERROR:
    case CYCLES_ERROR:
    case AREAS_ERROR:
    case PROGRESS_OPTIONS_ERROR:
      return {
        ...state,
        error: { isError: true, type: action.payload.type, msg: action.payload.msg }
      }
    default:
      return state;
  }
}
