export const ERRORS_GET = 'ERRORS_GET';
export const ERRORS_CLEAR = 'ERRORS_CLEAR';

export const SUCCESS_GET = 'SUCCESS_GET';
export const SUCCESS_CLEAR = 'SUCCESS_CLEAR';

export const ALERT_SET = 'ALERT_SET';
export const ALERT_REMOVE = 'ALERT_REMOVE';

// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_INIT = 'USER_INIT';
export const USER_INIT_CORRECT = 'USER_INIT_CORRECT';
export const USER_INIT_ERROR = 'USER_INIT_ERROR';

// UI
export const UI_LOADING_TRUE = 'UI_LOADING_TRUE';
export const UI_LOADING_FALSE = 'UI_LOADING_FALSE';

// Organization
export const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const ORG_INFO_GET = 'ORG_INFO_GET';
export const ORG_INFO_ERROR = 'ORG_INFO_ERROR';

export const WORKSPACES_GET = 'WORKSPACES_GET';
export const WORKSPACES_ERROR = 'WORKSPACES_ERROR';

export const MODULES_GET = 'MODULES_GET';
export const MODULES_ERROR = 'MODULES_ERROR';

export const SUBMODULES_GET = 'SUBMODULES_GET';
export const SUBMODULES_ERROR = 'SUBMODULES_ERROR';

export const CYCLES_GET = 'CYCLES_GET';
export const CYCLES_ERROR = 'CYCLES_ERROR';

export const AREAS_GET = 'AREAS_GET';
export const AREAS_ERROR = 'AREAS_ERROR';

export const ORG_FILTERS_SET = 'ORG_FILTERS_SET';

export const PROGRESS_OPTIONS_GET = 'PROGRESS_OPTIONS_GET';
export const PROGRESS_OPTIONS_ERROR = 'PROGRESS_OPTIONS_ERROR';

// Overview
export const MONTHLY_GRAPHIC_GET = 'MONTHLY_GRAPHIC_GET';
export const MONTHLY_GRAPHIC_ERROR = 'MONTHLY_GRAPHIC_ERROR';
export const DAILY_GRAPHIC_GET = 'DAILY_GRAPHIC_GET';
export const DAILY_GRAPHIC_ERROR = 'DAILY_GRAPHIC_ERROR';

export const PROGRESS_TABLES_GET = 'PROGRESS_TABLES_GET';
export const PROGRESS_GET = 'PROGRESS_GET';
export const PROGRESS_ERROR = 'PROGRESS_ERROR';

export const INDICATORS_PROGRESS_GET = 'INDICATORS_PROGRESS_GET';
export const INDICATORS_PROGRESS_ERROR = 'INDICATORS_PROGRESS_ERROR';

// Counters
export const COUNTERS_LOADING_TRUE = 'COUNTERS_LOADING_TRUE';
export const COUNTERS_LOADING_FALSE = 'COUNTERS_LOADING_FALSE';

export const COUNTERS_DATA_GET = 'COUNTERS_DATA_GET';
export const COUNTERS_DATA_GET_ERROR = 'COUNTERS_DATA_GET_ERROR';

// Reports
export const ALL_REPORTS_LOADING_TRUE = 'ALL_REPORTS_LOADING_TRUE';
export const ALL_REPORTS_LOADING_FALSE = 'ALL_REPORTS_LOADING_FALSE';
export const ALL_REPORTS_GET = 'ALL_REPORTS_GET';
export const ALL_REPORTS_GET_ERROR = 'ALL_REPORTS_GET_ERROR';

export const REPORT_CREATE = 'REPORT_CREATE';
export const REPORT_CREATE_ERROR = 'REPORT_CREATE_ERROR';

export const REPORT_INFO_GET = 'REPORT_INFO_GET';
export const REPORT_INFO_GET_ERROR = 'REPORT_INFO_GET_ERROR';
export const ORG_REPORTS_GET = 'ORG_REPORTS_GET';
export const ORG_REPORTS_GET_ERROR = 'ORG_REPORTS_GET_ERROR';

export const REPORT_UPDATE_LOADING_TRUE = 'REPORT_UPDATE_LOADING_TRUE';
export const REPORT_UPDATE_LOADING_FALSE = 'REPORT_UPDATE_LOADING_FALSE';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_UPDATE_ERROR = 'REPORT_UPDATE_ERROR';

export const REPORT_REMOVE = 'REPORT_REMOVE';
export const REPORT_REMOVE_ERROR = 'REPORT_REMOVE_ERROR';

export const REPORT_DOWNLOAD_LOADING_TRUE = 'REPORT_DOWNLOAD_LOADING_TRUE';
export const REPORT_DOWNLOAD_LOADING_FALSE = 'REPORT_DOWNLOAD_LOADING_FALSE';
export const REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
export const REPORT_DOWNLOAD_ERROR = 'REPORT_DOWNLOAD_ERROR';

export const REPORT_LOGS_LOADING_TRUE = 'REPORT_LOGS_LOADING_TRUE';
export const REPORT_LOGS_LOADING_FALSE = 'REPORT_LOGS_LOADING_FALSE';
export const ALL_REPORT_LOGS_GET = 'ALL_REPORT_LOGS_GET';
export const ALL_REPORT_LOGS_GET_ERROR = 'ALL_REPORT_LOGS_GET_ERROR';

// Apps
export const ALL_APPS_LOADING = 'ALL_APPS_LOADING';
export const ALL_APPS_GET = 'ALL_APPS_GET';
export const ALL_APPS_GET_ERROR = 'ALL_APPS_GET_ERROR';
