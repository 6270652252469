import Axios from 'axios';

import {
	UI_LOADING_TRUE,
	UI_LOADING_FALSE,
	MONTHLY_GRAPHIC_GET,
	MONTHLY_GRAPHIC_ERROR,
	DAILY_GRAPHIC_GET,
	DAILY_GRAPHIC_ERROR,
	PROGRESS_TABLES_GET,
	PROGRESS_GET,
	PROGRESS_ERROR,
	INDICATORS_PROGRESS_GET,
	INDICATORS_PROGRESS_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';
import createProgressData from '../utils/createProgressData';

export const ui_loading_true = () => dispatch => {
	dispatch({
		type: UI_LOADING_TRUE
	})
}

export const ui_loading_false = () => dispatch => {
	dispatch({
		type: UI_LOADING_FALSE
	})
}

export const getAreaType = (areaFilters) => {
	let area_type = '1', area = '';  // if there's no area filters

	if (areaFilters.region !== '') {
		area_type = '2';
		area = 'region';
	}
	if (areaFilters.zone !== '') {
		area_type = '3';
		area = 'zone';
	}
	if (areaFilters.depot !== '') {
		area_type = '4';
		area = 'depot';
	}
	if (areaFilters.group !== '') {
		area_type = '5';
		area = 'group';
	}

	return { area_type, area };
}

const createGraphicFilters = (orgFilters, areaFilters, cycleDuration) => {
	let graphicFilters = {};

	const isEmpty = Object.values(areaFilters).every(f => f === '');

	if (isEmpty) { // if there's no area filters
		graphicFilters = {
			...cycleDuration,
			workspace: orgFilters.workspace,
			data_type: '1',
			module: orgFilters.module,
		}
	}
	else {
		let { area_type, area } = getAreaType(areaFilters);

		graphicFilters = {
			...cycleDuration,
			data_type: '1',
			area_type: area_type,
			area: areaFilters[area],
			module: orgFilters.module,
		}
	}

	return graphicFilters;
}

const groupByDate = (data) => {
	const ts = [];
	const lng = localStorage.getItem('i18nextLng');

	data.forEach((tsValue) => {
		const date = new Date(tsValue.time);
		const day = date.toLocaleString(lng, { day: 'numeric', month: 'numeric' }).replace('/', '-');

		const foundGroup = ts.find(g => g.time === day);

		if (foundGroup) {
			foundGroup.value += tsValue.value;
		}
		else {
			const newTsValue = { time: day, value: tsValue.value }
			ts.push(newTsValue);
		}
	});

	return ts;
}

export const get_monthly_graphic_data = (orgFilters, areaFilters, cycleDuration) => dispatch => {
	let filters = createGraphicFilters(orgFilters, areaFilters, cycleDuration);
	// filters.bucket_size = '86400000';
	filters.bucket_size = '3600000';
	// console.log(filters)

	let url = process.env.REACT_APP_SERVER_URL + '/api/time/data?';
	let query = create_query_params(filters);
	url += query;

	process.env.REACT_APP_RUNTIME === 'Test' || process.env.REACT_APP_RUNTIME === 'Development' 
		&& console.log('monthly_graphic url =', url);

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MONTHLY_GRAPHIC_GET,
			// payload: res.data
			payload: groupByDate(res.data)
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MONTHLY_GRAPHIC_ERROR,
			payload: {type: 'monthly_graphic', msg: err.message}
		})
	})
}

export const get_daily_graphic_data = (orgFilters, areaFilters, cycleDuration) => dispatch => {
	let filters = createGraphicFilters(orgFilters, areaFilters, cycleDuration);
	filters.bucket_size = '3600000';
	// console.log(filters)

	let url = process.env.REACT_APP_SERVER_URL + '/api/time/data?';
	let query = create_query_params(filters);
	url += query;

	process.env.REACT_APP_RUNTIME === 'Test' || process.env.REACT_APP_RUNTIME === 'Development' 
		&& console.log('daily_graphic url =', url);  

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: DAILY_GRAPHIC_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: DAILY_GRAPHIC_ERROR,
			payload: {type: 'daily_graphic', msg: err.message}
		})
	})
}

const createProgressFilters = (progressFilters) => {
	let progressOptions = {};

	Object.keys(progressFilters).forEach((key) => {
		Object.entries(progressFilters[key]).forEach(([key, value]) => {
			progressOptions[key] = value;
		})
	});

	return progressOptions;
}

export const get_progress = (orgFilters, areaFilters, progressFilters, showStaffData) => dispatch => {
	let { area_type, area } = getAreaType(areaFilters);
	let progressOptions = createProgressFilters(progressFilters);
	
	let filters = { ...orgFilters, area_type: area_type, ...progressOptions, staff: showStaffData }

	if (area !== '') {
		filters[area] = areaFilters[area];
	}

	let url = process.env.REACT_APP_SERVER_URL + '/api/data/overview?';
	let query = create_query_params(filters);
	url += query;

	process.env.REACT_APP_RUNTIME === 'Test' || process.env.REACT_APP_RUNTIME === 'Development' 
		&& console.log('progress url =', url);

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: PROGRESS_TABLES_GET,
			payload: res.data.tables
		})

		// Sort progress data by name
		res.data.progress.data.sort((a, b) => a.name.localeCompare(b.name));
		// Create new progress data by adding total count
		if (res.data.progress.data.length > 0) {
			createProgressData(res.data.progress.data);
		}
		
		dispatch({
			type: PROGRESS_GET,
			payload: res.data.progress
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: PROGRESS_ERROR,
			payload: {type: 'progress', msg: err.message}
		})
	})
}

export const get_indicators_progress = (orgFilters, areaFilters, progressFilters) => dispatch => {
	let { area_type, area } = getAreaType(areaFilters);
	let progressOptions = createProgressFilters(progressFilters);
	
	let filters = { ...orgFilters, area_type: area_type, ...progressOptions }

	if (area !== '') {
		filters[area] = areaFilters[area];
	}

	let url = process.env.REACT_APP_SERVER_URL + '/api/data/overview?indicators=true&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INDICATORS_PROGRESS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INDICATORS_PROGRESS_ERROR,
			payload: {type: 'indicators_progress', msg: err.message}
		})
	})
}
