import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Container, Col, Row, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';

// Components
import ReportModal from './ReportModal';
import SelectButton from '../utils/SelectButton';
import TablePagination from '../utils/TablePagination';
import ConfirmDialog from '../utils/ConfirmDialog';
import StatusBadge from '../utils/StatusBadge';

// Actions
import { get_organizations, get_workspaces, get_modules } from '../../actions/organizationActions';
import { get_all_reports, remove_report } from '../../actions/reportsActions';
import { get_all_apps } from '../../actions/appsActions';

// Utils
import { getReportStatusDesc } from '../../utils/getStatusDesc';

// Images
import reportsImg from '../../images/reports-img.svg';

const Report = (props) => {
	const { reportInfo, allApps, showUpdateModal, setUpdateReport, skip, limit, dialogFunctions } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const removeReport = () => {
		dispatch(remove_report(reportInfo, skip, limit, dialogFunctions.setShowDialog));
	}

	const handleDialogFunctions = () => {		
		dialogFunctions.setShowDialog(true);
		dialogFunctions.setDialogFunction(() => removeReport);
	}

	return (
		<Card className='shadow mb-4 bg-body rounded card-border'>
			<Container>
				<Row>
					<Col style={{ cursor: 'pointer' }} onClick={() => window.open(`/reports/${reportInfo._id.$oid}`, '_self')}>
						<Row>
							<Col lg={4}>
								<Card.Body className='ps-0'>
									<Card.Title as='h6'>{reportInfo.name}</Card.Title>
									<Card.Subtitle className='mb-2 text-muted' as='p'>{reportInfo.description}</Card.Subtitle>
									<Card.Text>Alias: {reportInfo.alias}</Card.Text>
								</Card.Body>
							</Col>
							<Col className='d-flex align-items-center justify-content-center' lg={3}>
								<StatusBadge status={getReportStatusDesc(reportInfo.status)} />
							</Col>
							<Col className='d-flex align-items-center text-center' lg={4}>
								<Card.Body className='ps-0'>
									<i className='bi bi-laptop me-2'></i>
									<Card.Text>{allApps.apps.find(app => app._id.$oid === reportInfo?.app?.$oid)?.name}</Card.Text>
								</Card.Body>
							</Col>
						</Row>
					</Col>
					<Col className='d-flex align-items-center justify-content-around' md='auto'>
						<OverlayTrigger
							placement='bottom'
							overlay={<Tooltip id='button-tooltip-2'>{t('reportModal.form.buttons.edit')}</Tooltip>}
						>
							<Button className='reports-actions' onClick={() => { showUpdateModal(); setUpdateReport(reportInfo); }}>
								<i className='bi bi-pencil-fill fs-5'></i>
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement='bottom'
							overlay={<Tooltip id='button-tooltip-2'>{t('reportModal.form.buttons.remove')}</Tooltip>}
						>
							<Button className='reports-actions' onClick={handleDialogFunctions}>
								<i className='bi bi-trash-fill fs-5'></i>
							</Button>
						</OverlayTrigger>
					</Col>
				</Row>
			</Container>
		</Card>
	);
}

Report.propTypes = {
	reportInfo: PropTypes.object.isRequired,
	allApps: PropTypes.object.isRequired,
	showUpdateModal: PropTypes.func.isRequired,
	setUpdateReport: PropTypes.func.isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired,
	dialogFunctions: PropTypes.object.isRequired
}

const Reports = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };
	
	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	const { all_reports_loading, all_reports } = useSelector(state => state.reports);

	const { all_apps } = useSelector(state => state.apps);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [modalShow, setModalShow] = useState(false);
	const [create, setCreate] = useState(false);
	const [updateReport, setUpdateReport] = useState({});

	const [showDialog, setShowDialog] = useState(false);
	const [dialogFunction, setDialogFunction] = useState(null);

	const [skip, setSkip] = useState(0);
	const [limit] = useState(5);

	useEffect(() => {
		dispatch(get_organizations());
		dispatch(get_all_apps());

		setOrgFilters({
			...orgFilters,
			organization: _ls?.organization,
			workspace: _ls?.workspace || '',
			module: _ls?.module || ''
		});
	}, []);

	useEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}
	}, [orgFilters]);

	useEffect(() => {
		if (orgFilters.organization !== '') {
			const filters = {
				organization: orgFilters.organization,
				...(orgFilters.workspace !== '' && { workspace: orgFilters.workspace }),
				...(orgFilters.module !== '' && { module: orgFilters.module })
			}

			dispatch(get_all_reports({ ...filters, skip, limit }));
		}
	}, [orgFilters, skip]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
			localStorage.setItem(key, '');
		}

		if (e !== null){
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setOrgFilters(filters);
	}

	return (
		<Container className='mt-3 pb-5' fluid>
			<ConfirmDialog 
				show={showDialog}
				onHide={() => setShowDialog(false)}
				dialogFunction={dialogFunction}
			/>

			<ReportModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				create={create}
				filters={orgFilters}
				updateReport={updateReport}
				skip={skip}
				limit={limit}
			/>

			<Container className='wrapper-container px-0'>
				<Col className='title'>
					<h2 className='text-center'>{t('reports.title')}</h2>
					<p className='text-center'>{t('reports.instruction')}</p>
				</Col>
				<Col className='icons-btns'>
					<Container className='d-flex align-items-center justify-content-end px-0'>
						<Button className='simple-btn' size='lg' onClick={() => { setCreate(true); setModalShow(true); }}>
							<strong>{t('reports.createBtn')}</strong>
						</Button>
					</Container>
				</Col>
			</Container>

			<Container>
				<Row className='justify-content-center mt-4 mb-3'>
					<SelectButton
						label={t('landing.orgFilters.organization')}
						options={organizations !== undefined ? organizations : ['No organizations']}
						name='organization'
						value={orgFilters.organization ? orgFilters.organization : null}
						onChange={onChangeOrgFilters}
						disable={false}
						icon='house'
						orgFilter={true}
					/>
					<SelectButton
						label={t('landing.orgFilters.workspace')}
						options={workspaces !== undefined ? workspaces : ['No workspaces']}
						name='workspace'
						value={orgFilters.workspace ? orgFilters.workspace : null}
						onChange={onChangeOrgFilters}
						disable={orgFilters.organization === '' ? true : false}
						icon='list'
						orgFilter={true}
					/>
					<SelectButton
						label={t('overview.orgFilters.module')}
						options={modules !== undefined ? modules : ['No modules']}
						name='module'
						value={orgFilters.module === '' ? null : orgFilters.module}
						onChange={onChangeOrgFilters}
						disable={orgFilters.workspace === '' ? true : false}
						icon='box2'
						orgFilter={true}
					/>
				</Row>
			</Container>

			{orgFilters.organization !== ''
				?	<Container className='pt-5'>
						{all_reports_loading
							?	<div className='text-center'>
									<ClipLoader color='#004982' size={50} speedMultiplier={1} cssOverride={{ 'borderWidth': 'thick' }} />
								</div>
							:	all_reports.count > 0
								?	<Fragment>
										{all_reports.reports.map((report) => (
											<Report
												key={report._id.$oid}
												reportInfo={report}
												allApps={all_apps}
												showUpdateModal={() => { setCreate(false); setModalShow(true); }}
												setUpdateReport={setUpdateReport}
												skip={skip}
												limit={limit}
												dialogFunctions={{ setShowDialog: setShowDialog, setDialogFunction: setDialogFunction }}
											/>
										))}
										<TablePagination
											count={all_reports.count}
											arrayLength={all_reports.reports.length}
											nPages={Math.ceil(all_reports.count / limit)}
											skip={skip}
											setSkip={setSkip}
											limit={limit}
										/>
									</Fragment>
								:	<p className='text-center'>{t('reports.noReports')}</p>
						}
					</Container>
				: <img className='image' src={reportsImg} alt='Reports'/> 
			}
		</Container>
	)
}

export default Reports;
