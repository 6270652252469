import React from 'react';
import { useSelector } from 'react-redux';

// React Bootstrap
import { Container, Tabs, Tab } from 'react-bootstrap';

// Packages
import { useTranslation } from 'react-i18next';

// Components
import CountersTable from './CountersTable';

const CountersInfo = () => {
	const { t } = useTranslation();

	const { counters_loading, counters_current_data_loading, counters_data, counters_errors } = useSelector(state => state.counters);

	return (
		<Container>
			<Tabs
				defaultActiveKey='items'
				id='justify-tab-example'
				className='mt-3'
				justify
			>
				<Tab eventKey='items' title={t('countersInfo.countersTabs.items')}>
					<CountersTable data={counters_data.items} />
				</Tab>
				<Tab eventKey='photos' title={t('countersInfo.countersTabs.photos')}>
					<CountersTable data={counters_data.photos} />
				</Tab>
			</Tabs>
		</Container>
	)
}

export default CountersInfo;
