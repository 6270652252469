import React, { Fragment } from 'react';

// React Bootstrap
import { Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Utils
import { epochToLocalDate } from '../../utils/datesHandler';
import formatString from '../../utils/formatString';

const ReportLogData = (props) => {
	const { logData } = props;

	const parseData = (key, data) => {
		let logData = data;

		if (logData === null) {
			logData = 'Null'
		}
		else if (typeof logData !== 'string') {
			if (typeof logData === 'number') {
				if (key === 'timestamp') logData = epochToLocalDate(logData);
			}
			else if (typeof logData === 'boolean') {
				logData = 'False';

				if (logData) logData = 'True';
			}
		}

		return logData;
	}

	return (
		<Fragment>
			<Row>
				<h5>Data</h5>
				<Col>
					{Object.keys(logData).map((key, idx) => (
						<Fragment key={idx}>
							{typeof logData[key] !== 'object'
								?	<p className='m-0'><b>{formatString(key)}:</b> {parseData(key, logData[key])}</p>
								: logData[key] === null
									?	<p className='m-0'><b>{formatString(key)}:</b> {parseData(key, logData[key])}</p>
									:	Object.keys(logData[key]).map((innerKey, innerIdx) => (
											<Fragment key={innerIdx}>
												<hr/>
												<p className='m-0'><b>{formatString(innerKey)}:</b> {logData[key][innerKey]}</p>
											</Fragment>
										))
							}
						</Fragment>
					))}
				</Col>
			</Row>
		</Fragment>
	)
}

ReportLogData.propTypes = {
	logData: PropTypes.object.isRequired
}

export default ReportLogData;
