import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import BounceLoader from 'react-spinners/BounceLoader';
import { useTranslation } from 'react-i18next';

// Components
import SelectButton from '../utils/SelectButton';
import CycleDatesInfo from '../utils/CycleDatesInfo';
import CountersInfo from './CountersInfo';

// Images
import countersImg from '../../images/counters-img.svg';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

// Actions
import {
	get_organizations, get_workspaces,
	get_modules, get_submodules, get_cycles, get_areas, set_org_filters
} from '../../actions/organizationActions';
import { get_counters_current_data, get_counters_data } from '../../actions/countersActions';

const Counters = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);
	
	const { ui_loading } = useSelector(state => state.overview);

	const { organizations, workspaces, modules, submodules, cycles, areas } = useSelector(state => state.organization);
	
	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', submodule: '', cycle: '' });
	const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: ''});

	const [cycle, setCycle] = useState({});

	const [disableInputs, setDisableInputs] = useState(true);
	const [displayData, setDisplayData] = useState(false);

	useEffect(() => {
		dispatch(get_organizations());

		let lsOrganization = localStorage.getItem('organization');
		let lsWorkspace = localStorage.getItem('workspace');

		setOrgFilters({
			...orgFilters,
			organization: lsOrganization === null ? '' : lsOrganization,
			workspace: lsWorkspace === null ? '' : lsWorkspace
		});
	}, []);

	useDidMountEffect(() => {
		if (organizations.length <= 1) {
			setOrgFilters({
				...orgFilters,
				organization: user.organization
			});
			localStorage.setItem('organization', user.organization);
		}
	}, [organizations]);

	useDidMountEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
		}
	}, [orgFilters.organization]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
		}

		if (e !== null){
			filters[name] = e.value;
		}

		localStorage.setItem('organization', filters.organization);
		localStorage.setItem('workspace', filters.workspace);

		setOrgFilters(filters);
	}
	
	const onChangeAreaFilters = (e, name) => {
		let filters = {...areaFilters};
		let keys = Object.keys(filters);
		
		if (e === null){
			let idx = keys.indexOf(name);
			for (let index = idx; index < keys.length; index++) {
				const key = keys[index];
				filters[key] = '';
			}
		}
		else {
			filters[name] = e.value;
		}

		setAreaFilters(filters);
	}
	
	useDidMountEffect(() => {
		setDisplayData(false);

		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}

		if (orgFilters.module !== '') {
			dispatch(get_submodules({ module: orgFilters.module }));
		}

		if (orgFilters.workspace !== '') {
			if (orgFilters.module !== '') {
				dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
			}
		}

		if (orgFilters.cycle !== '') {
			dispatch(get_areas(orgFilters.workspace, orgFilters.cycle, areaFilters));
		}
	}, [orgFilters]);

	useDidMountEffect(() => {
		if (orgFilters.module !== '' && orgFilters.cycle !== ''){
			setDisableInputs(false);
		}
		else {
			setDisableInputs(true);
			setDisplayData(false);
		}
	}, [orgFilters]);

	useDidMountEffect(() => {
		if (orgFilters.cycle !== '') {
			dispatch(get_areas(orgFilters.workspace, orgFilters.cycle, areaFilters));
		}
	}, [orgFilters, areaFilters]);

	const handleRequests = () => {
		setDisplayData(true);

		let cycle = cycles.find(cycle => cycle._id.$oid === orgFilters.cycle);
		setCycle(cycle);

		dispatch(set_org_filters(orgFilters));

		if (cycle.status === 5) { // cycle has ended
			dispatch(get_counters_data(orgFilters, areaFilters));
		}
		else { // cycle is available
			dispatch(get_counters_current_data(orgFilters, areaFilters));
		}
	}

	useDidMountEffect(() => {
		handleRequests();
	}, [areaFilters]);

	const searchInfo = (e) => {
		e.preventDefault();
		handleRequests();
	}

	return ( 
		<div className='m-3 pb-5'>
			<h2 className='text-center'>{t('counters.title')}</h2>
			<p className='text-center'>{t('counters.instruction')}</p>

			{/* Organization Info Filters */}
			<div className='container-lg'>
				<div className='d-flex row align-items-center justify-content-center mt-4 mb-3'>
					{organizations.length > 1 &&
						<SelectButton
							label={t('landing.orgFilters.organization')}
							options={organizations !== undefined ? organizations : ['No organizations']}
							name='organization'
							value={orgFilters.organization ? orgFilters.organization : null}
							onChange={onChangeOrgFilters}
							disable={false}
							icon='house'
							orgFilter={true}
						/>
					}
					<SelectButton
						label={t('landing.orgFilters.workspace')}
						options={workspaces !== undefined ? workspaces : ['No workspaces']}
						name='workspace'
						value={orgFilters.workspace ? orgFilters.workspace : null}
						onChange={onChangeOrgFilters}
						disable={orgFilters.organization === '' ? true : false}
						icon='list'
						orgFilter={true}
					/>
				</div>
				<div className='d-flex row align-items-center justify-content-center mb-4'>
					<SelectButton
						label={t('overview.orgFilters.module')}
						options={modules !== undefined ? modules : ['No modules']}
						name='module'
						value={orgFilters.module === '' ? null : orgFilters.module}
						onChange={onChangeOrgFilters}
						disable={orgFilters.workspace === '' ? true : false}
						icon='box2'
						orgFilter={true}
					/>
					<SelectButton
						label={t('overview.orgFilters.submodule')}
						options={submodules !== undefined ? submodules : ['No submodules']}
						name='submodule'
						value={orgFilters.submodule === '' ? null : orgFilters.submodule}
						onChange={onChangeOrgFilters}
						disable={orgFilters.module === '' ? true : false}
						icon='box-seam'
						orgFilter={true}
					/>
					<SelectButton
						label={t('overview.orgFilters.cycle')}
						options={cycles !== undefined ? cycles : ['No cycles']}
						name='cycle'
						value={orgFilters.cycle === '' ? null : orgFilters.cycle}
						onChange={onChangeOrgFilters}
						disable={orgFilters.submodule === '' ? true : false}
						icon='arrow-repeat'
						orgFilter={true}
					/>
				</div>
			</div>

			<div className='d-grid gap-2 col-2 mx-auto text-center mt-4'>
				<button className='btn submit-btn' type='button' disabled={disableInputs} onClick={searchInfo}>
					{ui_loading
						? <div className='d-flex row align-items-center justify-content-center'>
								<BounceLoader color='#ffffff' size={20}/>
								Searching...
							</div>
						: <div>{t('overview.search')}</div>
					}
				</button>
			</div>

			{displayData
				?	<Fragment>
						{/* <hr/> */}
						<h4 className='text-center mt-4 mb-3'>{t('overview.filterBy')}:</h4>

						{/* Area Filters */}
						<div className='d-flex row align-items-center justify-content-center mb-4'>
							<div className='text-center mb-2'>
								<i className='bi bi-globe2 me-2'></i>{t('overview.areaFilters.title')}
							</div>
							<SelectButton
								label={t('overview.areaFilters.region')}
								options={'regions' in areas ? areas.regions : ['No regions']}
								name='region'
								value={areaFilters.region === '' ? null : areaFilters.region}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
							<SelectButton
								label={t('overview.areaFilters.zone')}
								options={'zones' in areas ? areas.zones : ['No zones']}
								name='zone'
								value={areaFilters.zone === '' ? null : areaFilters.zone}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
							<SelectButton
								label={t('overview.areaFilters.depot')}
								options={'depots' in areas ? areas.depots : ['No depots']}
								name='depot'
								value={areaFilters.depot === '' ? null : areaFilters.depot}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
							<SelectButton
								label={t('overview.areaFilters.group')}
								options={'groups' in areas ? areas.groups : ['No groups']}
								name='group'
								value={areaFilters.group === '' ? null : areaFilters.group}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
						</div>
						<hr className='hr-style'/>

						{/* Cycle dates info */}
						<CycleDatesInfo cycle={cycle} />

						{/* Counters data */}
						<CountersInfo />
					</Fragment>
				:	<img className='image' src={countersImg} alt='Counters'/> 
			}
		</div>
	);
}

export default Counters;
