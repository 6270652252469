import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import BounceLoader from 'react-spinners/BounceLoader';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Components
import Progress from './Progress';
import TableData from './TableData';
import Graphics from './Graphics';
import SelectButton from '../utils/SelectButton';
import CycleDatesInfo from '../utils/CycleDatesInfo';

// Images
import imgGif from '../../images/overview-img.svg';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

// Actions
import {
	get_organizations, get_workspaces,
	get_modules, get_cycles, get_areas, set_org_filters, get_progress_options
} from '../../actions/organizationActions';
import { get_monthly_graphic_data, get_daily_graphic_data, get_progress, get_indicators_progress } from '../../actions/overviewActions';
import { get_org_reports } from '../../actions/reportsActions';

// Utils
import isEmpty from '../../utils/isEmpty';

const Overview = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const user = useSelector(state => state.auth.user);
	
	const ui_loading = useSelector(state => state.overview.ui_loading);

	const { organizations, workspaces, modules, cycles, areas, progress_options } = useSelector(state => state.organization);
	
	const { org_reports } = useSelector(state => state.reports);
	
	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '', cycle: '' });
	const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: '' });
	const [areaLabels, setAreaLabels] = useState({ region: '', zone: '', depot: '', group: '' });
	const [progressFilters, setProgressFilters] = useState({ parent: {}, child: {} });

	const [cycle, setCycle] = useState({});
	const [dayToSearch, setDayToSearch] = useState('');
	const [showStaffData, setShowStaffData] = useState(false);

	const [disableInputs, setDisableInputs] = useState(true);
	const [displayData, setDisplayData] = useState(false);

	const [orgReports, setOrgReports] = useState([]);

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let _organization = '';

		if (organizations.length <= 1) {
			_organization = user.organization;
		}
		else {
			_organization = _ls?.organization;
		}
		
		setOrgFilters({
			...orgFilters,
			organization: _organization,
			workspace: _ls?.workspace || '',
			module: _ls?.module || '',
			cycle: _ls?.cycle || ''
		});
	}, [organizations]);

	useDidMountEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
		}
	}, [orgFilters.organization]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
			localStorage.setItem(key, '');
		}

		if (e !== null){
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setOrgFilters(filters);
	}

	const onChangeAreaFilters = (e, name) => {
		let filters = {...areaFilters};
		let labels = {...areaLabels};
		let keys = Object.keys(filters);
		
		if (e === null){
			let idx = keys.indexOf(name);
			for (let index = idx; index < keys.length; index++) {
				const key = keys[index];
				filters[key] = '';
				labels[key] = '';
				localStorage.setItem(key, '');
			}
		}
		else {
			filters[name] = e.value;
			labels[name] = e.label;
			localStorage.setItem(name, e.value);
		}

		setAreaFilters(filters);
		setAreaLabels(labels);
	}
	
	useDidMountEffect(() => {
		setDisplayData(false);

		if (orgFilters.organization !== '') {
			dispatch(get_modules({ organization: orgFilters.organization }));
		}

		if (orgFilters.workspace !== '') {
			if (orgFilters.module !== '') {
				dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
			}
			dispatch(get_progress_options({ workspace: orgFilters.workspace }));
		}
	}, [orgFilters]);

	useDidMountEffect(() => {
		if (orgFilters.module !== '' && orgFilters.cycle !== ''){
			setDisableInputs(false);
		}
		else {
			setDisableInputs(true);
			setDisplayData(false);
		}
	}, [orgFilters]);

	useDidMountEffect(() => {
		if (orgFilters.cycle !== '') {
			dispatch(get_areas(orgFilters.workspace, orgFilters.cycle, areaFilters));
		}
	}, [orgFilters, areaFilters]);

	const onChangeProgressFilters = (e, name) => {
		let parentFilters = {...progressFilters.parent};
		let childFilters = {...progressFilters.child};

		if (e !== null) {
			if (parentFilters.hasOwnProperty('child_tables')) { // parent was a child filter 
				// change from parent to child
				childFilters = {
					child: parentFilters.option,
					child_value: parentFilters.option_value
				};
			}

			// set parent
			parentFilters = { option: name, option_value: e.value }
		}
		else {
			const isChildEmpty = isEmpty(childFilters);

			if (!isChildEmpty) { // if child filter is not empty
				// change from child to parent
				parentFilters = {
					option: childFilters?.child,
					option_value: childFilters?.child_value,
					child_tables: true
				};
				childFilters = {};
			}
			else { // if child is empty
				parentFilters = {};
				childFilters = {};
			}
		}

		setProgressFilters({
			parent: parentFilters, child: childFilters
		});
	}

	useDidMountEffect(() => {
		const day = moment(dayToSearch, 'YYYY-MM-DD');

		let dayDuration = {
			start: Math.trunc(day.startOf('day').valueOf() / 1000),
			end: Math.trunc(day.endOf('day').valueOf() / 1000)
		}

		if (dayToSearch !== '') {
			dispatch(get_daily_graphic_data(orgFilters, areaFilters, dayDuration));
		}
	}, [dayToSearch]);

	const handleRequests = () => {
		setDisplayData(true);

		let cycle = cycles.find(cycle => cycle._id.$oid === orgFilters.cycle);
		setCycle(cycle);

		dispatch(set_org_filters(orgFilters));

		let cycleDuration = {
			start: cycle.start.$date / 1000,
			end: cycle.end.$date / 1000
		}

		let day = null;
		let dayDuration = {};

		if (cycle.status === 5) { // cycle has ended
			day = moment(cycle.end.$date);
			dayDuration = {
				start: Math.trunc(day.startOf('day').valueOf() / 1000),
				end: Math.trunc(day.endOf('day').valueOf() / 1000)
			}
			setDayToSearch(moment(cycle.end.$date).format('YYYY-MM-DD'));
		}
		else { // cycle is available
			day = moment();
			dayDuration = {
				start: Math.trunc(day.startOf('day').valueOf() / 1000),
				end: Math.trunc(day.endOf('day').valueOf() / 1000)
			}
			setDayToSearch(moment().format('YYYY-MM-DD'));
		}

		dispatch(get_monthly_graphic_data(orgFilters, areaFilters, cycleDuration));
		dispatch(get_daily_graphic_data(orgFilters, areaFilters, dayDuration));

		dispatch(get_progress(orgFilters, areaFilters, progressFilters, showStaffData));
		// dispatch(get_indicators_progress(orgFilters, areaFilters, progressFilters));

		dispatch(get_org_reports({
			app: process.env.REACT_APP_ALIAS,
			organization: orgFilters.organization,
			workspace: orgFilters.workspace,
			module: orgFilters.module
		}));
	}

	useDidMountEffect(() => {
		handleRequests();
	}, [areaFilters, progressFilters, showStaffData]);

	useDidMountEffect(() => {
		if (displayData && org_reports.length > 0) {
			const filteredOrgReports = org_reports.filter(report => 
				orgFilters.organization === report.organization.$oid && 
				orgFilters.workspace === report.workspace.$oid && 
				orgFilters.module === report.module.$oid
			);
			setOrgReports(filteredOrgReports);
		}
	}, [org_reports]);

	const searchInfo = (e) => {
		e.preventDefault();
		handleRequests();
	}

	return ( 
		<div className='m-3 pb-5'>
			<h2 className='text-center'>Overview</h2>
			<p className='text-center'>{t('landing.instruction')}</p>

			{/* Organization Info Filters */}
			<div className='d-flex row align-items-center justify-content-center mt-4 mb-3'>
				{organizations.length > 1 &&
					<SelectButton
						label={t('landing.orgFilters.organization')}
						options={organizations !== undefined ? organizations : ['No organizations']}
						name='organization'
						value={orgFilters.organization ? orgFilters.organization : null}
						onChange={onChangeOrgFilters}
						disable={false}
						icon='house'
						orgFilter={true}
					/>
				}
				<SelectButton
					label={t('landing.orgFilters.workspace')}
					options={workspaces !== undefined ? workspaces : ['No workspaces']}
					name='workspace'
					value={orgFilters.workspace ? orgFilters.workspace : null}
					onChange={onChangeOrgFilters}
					disable={orgFilters.organization === '' ? true : false}
					icon='list'
					orgFilter={true}
				/>
			</div>
			<div className='d-flex row align-items-center justify-content-center mb-4'>
				<SelectButton
					label={t('overview.orgFilters.module')}
					options={modules !== undefined ? modules : ['No modules']}
					name='module'
					value={orgFilters.module === '' ? null : orgFilters.module}
					onChange={onChangeOrgFilters}
					disable={orgFilters.workspace === '' ? true : false}
					icon='box2'
					orgFilter={true}
				/>
				<SelectButton
					label={t('overview.orgFilters.cycle')}
					options={cycles !== undefined ? cycles : ['No cycles']}
					name='cycle'
					value={orgFilters.cycle === '' ? null : orgFilters.cycle}
					onChange={onChangeOrgFilters}
					disable={orgFilters.module === '' ? true : false}
					icon='arrow-repeat'
					orgFilter={true}
				/>
			</div>

			<div className='d-grid gap-2 col-2 mx-auto text-center mt-4'>
				<button className='btn submit-btn' type='button' disabled={disableInputs} onClick={searchInfo}>
					{ui_loading
						? <div className='d-flex row align-items-center justify-content-center'>
								<BounceLoader color='#ffffff' size={20}/>
								Searching...
							</div>
						: <div>{t('overview.search')}</div>
					}
				</button>
			</div>

			{displayData
				? <Fragment>
						{/* <hr/> */}
						<h4 className='text-center mt-4 mb-3'>{t('overview.filterBy')}:</h4>

						{/* Area Filters */}
						<div className='d-flex row align-items-center justify-content-center mb-4'>
							<div className='text-center mb-2'>
								<i className='bi bi-globe2 me-2'></i>{t('overview.areaFilters.title')}
							</div>
							<SelectButton
								label={t('overview.areaFilters.region')}
								options={'regions' in areas ? areas.regions : ['No regions']}
								name='region'
								value={areaFilters.region === '' ? null : areaFilters.region}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
							<SelectButton
								label={t('overview.areaFilters.zone')}
								options={'zones' in areas ? areas.zones : ['No zones']}
								name='zone'
								value={areaFilters.zone === '' ? null : areaFilters.zone}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
							<SelectButton
								label={t('overview.areaFilters.depot')}
								options={'depots' in areas ? areas.depots : ['No depots']}
								name='depot'
								value={areaFilters.depot === '' ? null : areaFilters.depot}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
							<SelectButton
								label={t('overview.areaFilters.group')}
								options={'groups' in areas ? areas.groups : ['No groups']}
								name='group'
								value={areaFilters.group === '' ? null : areaFilters.group}
								onChange={onChangeAreaFilters}
								disable={disableInputs}
								orgFilter={false}
							/>
						</div>
						<div className='d-flex align-items-center justify-content-center mb-3'>
							<div className="form-check form-switch">
								<input
									className="form-check-input"
									type="checkbox"
									role="switch"
									id="flexSwitchCheckChecked"
									checked={showStaffData}
									onChange={(e) => {
										e.preventDefault();
										setShowStaffData(!showStaffData);
									}}
								/>
								<label className="form-check-label" htmlFor="flexSwitchCheckChecked">{t('overview.areaFilters.staff')}</label>
							</div>
						</div>
						<hr className='hr-style'/>

						{/* Options Filters */}
						{progress_options.length > 0 &&
							<Fragment>
								<div className='d-flex row align-items-center justify-content-center mt-4 mb-4'>
									<div className='text-center mb-2'>
										<i className='bi bi-gear me-2'></i>{t('overview.optionFilters.title')}
									</div>
									{progress_options.map((p_opt, idx) => (
										<Fragment key={idx}>
											<SelectButton
												label={p_opt.name}
												options={p_opt.values.length !== 0 ? p_opt.values : []}
												name={p_opt._id}
												onChange={onChangeProgressFilters}
												disable={disableInputs}
												orgFilter={false}
												optFilter={true}
											/>
										</Fragment>
									))}
									<div id='progressOptionsHelp' className='form-text text-center mb-4'>
										{t('overview.optionFilters.legend')}
									</div>
								</div>
								<hr className='hr-style'/>
							</Fragment>
						}

						{/* Cycle dates info */}
						<CycleDatesInfo cycle={cycle} />
						
						{/* Bar charts */}
						<Graphics cycle={cycle} dayToSearch={dayToSearch} setDayToSearch={setDayToSearch} />
						<hr className='hr-style'/>
			
						{/* Progress */}
						<Progress progressFilters={progressFilters} setProgressFilters={setProgressFilters} />
						<hr className='hr-style'/>
			
						{/* Progress table */}
						<TableData
							cycle={cycle}
							orgFilters={orgFilters}
							areaFilters={areaFilters}
							areaLabels={areaLabels}
							progressFilters={progressFilters}
							org_reports={orgReports}
						/>
					</Fragment>
				: <img className='image' src={imgGif} alt='Overview'/> 
			}
		</div>
	);
}

export default Overview;
