export const SPANISH_TRANSLATIONS = {
  menu: {
    account: 'Cuenta',
    return: 'Regresar al menú',
    logOut: 'Cerrar sesión'
  },
  landing: {
    instruction: 'Usa los filtros para poder visualizar estadísticas relacionadas con los payloads realizados.',
    orgFilters: {
      organization: 'Organización',
      workspace: 'Workspace'
    }
  },

  // OVERVIEW
  overview: {
    orgFilters: {
      module: 'Módulo',
      submodule: 'Submódulo',
      cycle: 'Ciclo'
    },
    search: 'Buscar',
    filterBy: 'Filtrar por',
    areaFilters: {
      title: 'Áreas',
      region: 'Región',
      zone: 'Zona',
      depot: 'Depot',
      group: 'Grupo',
      route: 'Ruta',
			staff: 'Usuarios Supervisores'
    },
    optionFilters: {
      title: 'Opciones',
      legend: 'Sólo puede filtrar por una opción, borre todas las demás antes de seleccionar una.'
    }
  },
  select: {
    region: 'Cant. de Regiones:',
    zone: 'Cant. de Zonas:',
    depot: 'Cant. de Depots:',
    group: 'Cant. de Grupos:',
    route: 'Cant. de Rutas:'
  },
  cycle: {
    select: {
      start: 'Inicio',
      end: ' Fin'
    },
    start: 'Inicio de ciclo:',
    end: ' Fin de ciclo:',
    workingDays: 'Días laborables:',
    currentDays: 'Días transcurridos:',
    remainingDays: 'Días restantes:'
  },
  graphics: {
    title: 'Gráficas',
    graphicsError: 'Error al cargar las gráficas',
    monthlyGraphic: 'Payloads por día',
    dailyGraphic: 'Payloads por hora',
    dailyGraphicError: 'Sin información'
  },
  progress: {
    progressByTable: '% de Avance por',
    progressByError: 'Sin información',
    pie: {
      title: 'Porcentaje de Avance',
      available: 'Por evaluar',
      work: 'Realizados',
      issues: 'Incidencias'
    },
    workSpeedometer: {
      title: 'Porcentaje de Avance de Realizados',
      type: 'Realizados',
    },
    issuesSpeedometer: {
      title: 'Porcentaje de Avance de Incidencias',
      type: 'Incidencias',
    }
  },
  progressTable: {
    area: 'Área',
    available: 'Por Evaluar',
    work: 'Realizados',
    workPercentage: 'Porcentaje de Realizados',
		visits: 'Visitas',
    issuesTypes: {
      header: 'Incidencias',
      closed: 'Cerrada',
      closedDown: 'Cerrada Permanentemente',
      access: 'Sin Acceso',
      process: 'No se Evaluó',
      products: 'No Tiene Producto',
      custom: 'Personalizada'
    },
    issues: 'Total Incidencias',
    issuesPercentage: 'Porcentaje de Incidencias',
    notWorked: 'Sin Realizar',
    notWorkedPercentage: 'Porcentaje sin Realizar',
    total: 'Avance Total',
    totalPercentage: 'Porcentaje de Avance Total',
    currentProgress: 'Avance Actual',
		dailyPayloads: 'Payloads Diarios Necesarios',
		scoreAverage: 'Promedio de Calificación',
    error: 'Sin Información'
  },

  // UTILS COMPONENTS
  selectButton: {
    placeholder: 'Seleccionar...',
    noOptionsMessage: 'Sin opciones'
  },
	barChart: {
		tooltip: 'Agregado: '
	},

  // STATUS DESCRIPTIONS
  statusDesc: {
    none: 'None',
    created: 'Creado',
    starting: 'Empezando',
    available: 'Disponible',
    down: 'Abajo',
    maintenance: 'Mantenimiento',
    deprecated: 'Obsoleto',
    removed: 'Eliminado',
    working: 'En Proceso',
    stopped: 'Detenido',
    ended: 'Terminado',
    disabled: 'Deshabilitado',
    ready: 'Listo',
    waiting: 'Esperando',
    processing: 'Procesando',
    good: 'Correcto',
    completed: 'Completado',
    incomplete: 'Incompleto',
    failed: 'Fallo',
    error: 'Error'
  },

  // COUNTERS
  counters: {
    title: 'Estadísticas',
    instruction: 'Usa los filtros para poder visualizar estadísticas de conteo de items y fotografías.'
  },
  countersInfo: {
    countersTabs: {
      items: 'Items',
      photos: 'Fotos'
    }
  },
  countersTable: {
    headers: {
      key: 'Llave',
      value: 'Cantidad'
    },
    errors: {
      noCounters: 'No se encontraron counters'
    }
  },

  // REPORTS
  reports: {
    title: 'Reportes',
    instruction: 'Usa los filtros para poder visualizar los reportes que tiene cada organización.',
    createBtn: 'Crear',
    noReports: 'No hay reportes disponibles para esta organizacion',
    download: 'Reporte de'
  },
  reportModal: {
    type: {
      createTitle: 'Crear Reporte',
      updateTitle: 'Editar Reporte'
    },
    form: {
      name: 'Nombre',
      description: 'Descripción',
      action: 'Acción',
      createTab: 'Crear Tab',
      createTabOptions: {
        true: 'Verdadero',
        false: 'Falso'
      },
      buttons: {
        create: 'Crear',
        creating: 'Creando',
        edit: 'Editar',
        editing: 'Editando',
        remove: 'Eliminar',
        cancel: 'Cancelar'
      }
    },
    errorAlertMsg: 'Faltan campos por completar!'
  },
  reportInfo: {
    title: 'Información del Reporte',
    date: 'Fecha',
    status: 'Estado',
    logsTable: {
      headers: {
        user: 'Usuario',
        completed: 'Completado (seg)'
      },
      errors: {
        noLogs: 'No hay logs'
      }
    }
  },

  // PAGINATION
  pagination: {
    of: 'de'
  },

  // CONFIRM DIALOG
  confirmDialog: {
    title: '¿Estás seguro?',
    text: 'Esta acción no se puede deshacer.',
    confirm: 'Confirmar',
    cancel: 'Cancelar'
  }
};
