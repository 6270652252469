import {
	ALL_REPORTS_LOADING_TRUE,
	ALL_REPORTS_LOADING_FALSE,
	ALL_REPORTS_GET,
	ALL_REPORTS_GET_ERROR,
	REPORT_CREATE,
	REPORT_CREATE_ERROR,
	REPORT_INFO_GET,
	REPORT_INFO_GET_ERROR,
	ORG_REPORTS_GET,
	ORG_REPORTS_GET_ERROR,
	REPORT_UPDATE_LOADING_TRUE,
	REPORT_UPDATE_LOADING_FALSE,
	REPORT_UPDATE,
	REPORT_UPDATE_ERROR,
	REPORT_REMOVE,
	REPORT_REMOVE_ERROR,
	REPORT_DOWNLOAD_LOADING_TRUE,
	REPORT_DOWNLOAD_LOADING_FALSE,
	REPORT_DOWNLOAD,
	REPORT_DOWNLOAD_ERROR,
	REPORT_LOGS_LOADING_TRUE,
	REPORT_LOGS_LOADING_FALSE,
	ALL_REPORT_LOGS_GET,
	ALL_REPORT_LOGS_GET_ERROR
} from '../actions/types';

const initialState = {
	all_reports_loading: false,
	all_reports: { count: 0, reports: [] },
	create_report_success: false,
	report_info: {},
	org_reports: [],
	update_report_loading: false,
	update_report_success: false,
	remove_report_success: false,
	download_report_loading: { loading: false, report_id: '' },
	all_report_logs_loading: false,
	all_report_logs: { count: 0, logs: [] },
	reports_errors: {}
}

export default function reportsReducer(state = initialState, action){
	switch(action.type){
		case ALL_REPORTS_LOADING_TRUE:
			return {
				...state,
				all_reports_loading: true
			}
		case ALL_REPORTS_LOADING_FALSE:
			return {
				...state,
				all_reports_loading: false
			}
		case ALL_REPORTS_GET:
			delete state.reports_errors.all_reports
			return {
				...state,
				all_reports: {
					count: action.payload.count,
					reports: action.payload.reports
				}
			}
		case REPORT_CREATE:
			delete state.reports_errors.create_report
			return {
				...state,
				create_report_success: action.payload
			}
		case REPORT_INFO_GET:
			delete state.reports_errors.report_info
			return {
				...state,
				report_info: action.payload
			}
		case ORG_REPORTS_GET:
			delete state.reports_errors.organization_reports
			return {
				...state,
				org_reports: action.payload
			}
		case REPORT_UPDATE_LOADING_TRUE:
			return {
				...state,
				update_report_loading: true
			}
		case REPORT_UPDATE_LOADING_FALSE:
			return {
				...state,
				update_report_loading: false
			}
		case REPORT_UPDATE:
			delete state.reports_errors.update_report
			return {
				...state,
				update_report_success: action.payload
			}
		case REPORT_REMOVE:
			delete state.reports_errors.remove_report
			return {
				...state,
				remove_report_success: action.payload
			}
		case REPORT_DOWNLOAD_LOADING_TRUE:
			return {
				...state,
				download_report_loading: {
					loading: true,
					report_id: action.payload
				}
			}
		case REPORT_DOWNLOAD_LOADING_FALSE:
			return {
				...state,
				download_report_loading: {
					loading: false,
					report_id: action.payload
				}
			}
		case REPORT_DOWNLOAD:
			delete state.reports_errors.download_report
			return {
				...state
			}
		case REPORT_LOGS_LOADING_TRUE:
			return {
				...state,
				all_report_logs_loading: true
			}
		case REPORT_LOGS_LOADING_FALSE:
			return {
				...state,
				all_report_logs_loading: false
			}
		case ALL_REPORT_LOGS_GET: 
			delete state.reports_errors.all_report_logs
			return {
				...state,
				all_report_logs: {
					count: action.payload.count,
					logs: action.payload.logs
				}
			}
		case ALL_REPORTS_GET_ERROR:
		case REPORT_CREATE_ERROR:
		case REPORT_INFO_GET_ERROR:
		case ORG_REPORTS_GET_ERROR:
		case REPORT_UPDATE_ERROR:
		case REPORT_REMOVE_ERROR:
		case REPORT_DOWNLOAD_ERROR:
		case ALL_REPORT_LOGS_GET_ERROR:
			return {
				...state,
				reports_errors: {
					...state.reports_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
