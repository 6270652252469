import {
	UI_LOADING_TRUE,
	UI_LOADING_FALSE,
	MONTHLY_GRAPHIC_GET,
	MONTHLY_GRAPHIC_ERROR,
	DAILY_GRAPHIC_GET,
	DAILY_GRAPHIC_ERROR,
	PROGRESS_TABLES_GET,
	PROGRESS_GET,
	PROGRESS_ERROR,
	INDICATORS_PROGRESS_GET,
	INDICATORS_PROGRESS_ERROR
} from '../actions/types';

const initialState = {
	ui_loading: false,
	monthly_graphic_data: [],
	daily_graphic_data: [],
	tables: [],
	progress: {
		total_available: 0,
		total_work: 0,
		total_issues: 0,
		data: []
	},
	indicators_progress: [],
	error: { isError: false, type: '', msg: '' }
}

export default function overviewReducer(state = initialState, action){
	switch(action.type){
		case UI_LOADING_TRUE:
			return {
				...state,
				loading: true,
				error: { isError: false, type: '', msg: '' }
			}
		case UI_LOADING_FALSE:
			return {
				...state,
				loading: false
			}
		case MONTHLY_GRAPHIC_GET:
			return {
				...state,
				monthly_graphic_data: action.payload,
				error: { isError: false, type: '', msg: '' }
			}
		case DAILY_GRAPHIC_GET:
			return {
				...state,
				daily_graphic_data: action.payload,
				error: { isError: false, type: '', msg: '' }
			}
		case PROGRESS_TABLES_GET:
			return {
				...state,
				tables: action.payload
			}
		case PROGRESS_GET:
			return {
				...state,
				progress: {
					total_available: action.payload.total_available,
					total_work: action.payload.total_work,
					total_issues: action.payload.total_issues,
					data: action.payload.data
				}
			}
		case INDICATORS_PROGRESS_GET:
			return {
				...state,
				indicators_progress: action.payload
			}
		case MONTHLY_GRAPHIC_ERROR:
		case DAILY_GRAPHIC_ERROR:
		case PROGRESS_ERROR:
		case INDICATORS_PROGRESS_ERROR:
			return {
				...state,
				error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
