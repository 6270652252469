export const ENGLISH_TRANSLATIONS = {
  menu: {
    account: 'Account',
    return: 'Return to menu',
    logOut: 'Log Out'
  },
  landing: {
    instruction: 'Use the filters to visualize statistics related to payloads done.',
    orgFilters: {
      organization: 'Organization',
      workspace: 'Workspace'
    }
  },

  // OVERVIEW
  overview: {
    orgFilters: {
      module: 'Module',
      submodule: 'Submodule',
      cycle: 'Cycle'
    },
    search: 'Search',
    filterBy: 'Filter by',
    areaFilters: {
      title: 'Areas',
      region: 'Region',
      zone: 'Zone',
      depot: 'Depot',
      group: 'Group',
      route: 'Route',
			staff: 'Staff Users'
    },
    optionFilters: {
      title: 'Options',
      legend: 'You can only filter by one option, clear all others before selecting one.'
    }
  },
  select: {
    region: 'Qty. of Regions:',
    zone: 'Qty. of Zones:',
    depot: 'Qty. of Depots:',
    group: 'Qty. of Groups:',
    route: 'Qty. of Routes:'
  },
  cycle: {
    select: {
      start: 'Start',
      end: ' End'
    },
    start: 'Start of cycle:',
    end: ' End of cycle:',
    workingDays: 'Working days:',
    currentDays: 'Days elapsed:',
    remainingDays: 'Remaining days:'
  },
  graphics: {
    title: 'Graphics',
    graphicsError: 'Error loading graphics',
    monthlyGraphic: 'Payloads per day',
    dailyGraphic: 'Payloads per hour',
    dailyGraphicError: 'No information'
  },
  progress: {
    progressByTable: '% of Progress by',
    progressByError: 'No information',
    pie: {
      title: 'Progress Percentage',
      available: 'Available',
      work: 'Work',
      issues: 'Issues'
    },
    workSpeedometer: {
      title: 'Work Progress Percentage',
      type: 'Work',
    },
    issuesSpeedometer: {
      title: 'Issues Progress Percentage',
      type: 'Issues',
    }
  },
  progressTable: {
    area: 'Area',
    available: 'Available',
    work: 'Work',
    workPercentage: 'Work Percentage',
		visits: 'Visits',
    issuesTypes: {
      header: 'Issues',
      closed: 'Closed',
      closedDown: 'Closed Down',
      access: 'No Access',
      process: 'No Process',
      products: 'No Products',
      custom: 'Custom'
    },
    issues: 'Total issues',
    issuesPercentage: 'Issues Percentage',
    notWorked: 'Not  Worked',
    notWorkedPercentage: 'Not  Worked Percentage',
    total: 'Total Progress',
    totalPercentage: 'Total Progress Percentage',
    currentProgress: 'Current Progress',
		dailyPayloads: 'Daily Payloads Required',
		scoreAverage: 'Average Score',
    error: 'No Information'
  },

  // UTILS COMPONENTS
  selectButton: {
    placeholder: 'Select...',
    noOptionsMessage: 'No options'
  },
	barChart: {
		tooltip: 'Aggregate: '
	},

  // STATUS DESCRIPTIONS
  statusDesc: {
    none: 'None',
    created: 'Created',
    starting: 'Starting',
    available: 'Available',
    down: 'Down',
    maintenance: 'Maintenance',
    deprecated: 'Deprecated',
    removed: 'Removed',
    working: 'Working Proceso',
    stopped: 'Stopped',
    ended: 'Ended',
    disabled: 'Disabled',
    ready: 'Ready',
    waiting: 'Waiting',
    processing: 'Processing',
    good: 'Good',
    completed: 'Completed',
    incomplete: 'Incomplete',
    failed: 'Failed',
    error: 'Error'
  },

  // COUNTERS
  counters: {
    title: 'Counters',
    instruction: 'Use the filters to visualize item and photos counters.'
  },
  countersInfo: {
    countersTabs: {
      items: 'Items',
      photos: 'Photos'
    }
  },
  countersTable: {
    headers: {
      key: 'Key',
      value: 'Quantity'
    },
    errors: {
      noCounters: 'No counters found'
    }
  },

  // REPORTS
  reports: {
    title: 'Reports',
    instruction: 'Use the filters to visualize the reports from each organization.',
    createBtn: 'Create',
    noReports: 'No reports available for this organization',
    download: 'Report of'
  },
  reportModal: {
    type: {
      createTitle: 'Create Report',
      updateTitle: 'Edit Report'
    },
    form: {
      name: 'Name',
      description: 'Description',
      action: 'Action',
      createTab: 'Create Tab',
      createTabOptions: {
        true: 'True',
        false: 'False'
      },
      buttons: {
        create: 'Create',
        creating: 'Creating',
        edit: 'Edit',
        editing: 'Editing',
        remove: 'Remove',
        cancel: 'Cancel'
      }
    },
    errorAlertMsg: 'Missing fields!'
  },
  reportInfo: {
    title: 'Report Information',
    date: 'Date',
    status: 'Status',
    logsTable: {
      headers: {
        user: 'User',
        completed: 'Completed (sec)'
      },
      errors: {
        noLogs: 'No logs'
      }
    }
  },

  // PAGINATION
  pagination: {
    of: 'of'
  },

  // CONFIRM DIALOG
  confirmDialog: {
    title: 'Are you sure?',
    text: 'This action can not be undone.',
    confirm: 'Confirm',
    cancel: 'Cancel'
  }
};
