import {
	ALL_APPS_LOADING,
	ALL_APPS_GET,
	ALL_APPS_GET_ERROR
} from '../actions/types';

const initialState = {
	all_apps_loading: false,
	all_apps: { count: 0, apps: [] },
	apps_errors: {}
}

export default function appsReducer(state = initialState, action){
	switch(action.type){
		case ALL_APPS_LOADING:
			return {
				...state,
				all_apps_loading: true
			}
		case ALL_APPS_GET:
			delete state.apps_errors.all_apps
			return {
				...state,
				all_apps_loading: false,
				all_apps: {
					count: action.payload.count,
					apps: action.payload.apps
				}
			}
		case ALL_APPS_GET_ERROR:
			return {
				...state,
				all_apps_loading: false,
				apps_errors: {
					...state.apps_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
