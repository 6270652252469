import React, { Fragment } from 'react';

// Packages
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CountersTable = (props) => {
	const { data } = props;

	const { t } = useTranslation();

	const formatCacheKey = (key = '') => {
		let newKey = '';
		
		key.split(':').forEach((word) => { newKey += word.charAt(0).toUpperCase() + word.slice(1) + ' '	});

		return newKey;
	}

	return (
		<table className='table mt-3 mb-5'>
			<thead className='table-head'>
				<tr>
					<th scope='col'>Id</th>
					<th scope='col'>{t('countersTable.headers.key')}</th>
					<th scope='col'>{t('countersTable.headers.value')}</th>
				</tr>
			</thead>
			<tbody>
				{data.length > 0
					?	<Fragment>
							{data.map((counter, idx) => (
								<tr key={idx}>
									<td className='text-break'>{counter.counter._id.$oid}</td>
									<td>{formatCacheKey(counter.counter.cache_key)}</td>
									<td>
										{counter.data === null || counter.data === undefined
											? counter.value === null || counter.value === undefined ? 0 : counter.value
											: counter.data
										}
									</td>
								</tr>
							))}
						</Fragment>
					:	<tr>
							<td className='text-center' colSpan={3}>{t('countersTable.errors.noCounters')}</td>
						</tr>
				}
			</tbody>
		</table>
	)
}

CountersTable.propTypes = {
	data: PropTypes.array.isRequired
}

export default CountersTable;
